import 'regenerator-runtime'
import { init as initSentry, vueRouterInstrumentation, BrowserTracing, Replay } from '@sentry/vue'
import { CaptureConsole } from '@sentry/integrations'
import FloatingVue from 'floating-vue'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import VueNotification from '@kyvg/vue3-notification'
import { VueQueryPlugin, VueQueryPluginOptions } from '@tanstack/vue-query'
import { register as registerSwiper } from 'swiper/element/bundle'
import App from './App.vue'
import i18n from './i18n'
import { placeholder } from './libs/directives/placeholder'
import { whiteSpace } from './libs/directives/whiteSpace'
import { vSafeHtml } from './libs/directives/safe-html'
import { vRenderWhenChildren } from './libs/directives/renderWhenChildren'
import { initRouter } from './router'
import { QueryOptions } from './vars/QueryAttr'
import { ThemeTooltip } from '@/vars/ThemeAttr'

import '@/libs/sass/_main.scss'
import 'normalize.css'
import './vee-validate'
import 'floating-vue/dist/style.css'
import { initGoogleAnalytics } from './libs/vendors/google/analytics/google-analytics'
import '@fontsource-variable/material-symbols-rounded/wght.css'

const pinia = createPinia()

registerSwiper()

// Obliger d'attendre la fin de l'initialisation du router sinon error Cannot access XXX before init
initRouter().then((router) => {
    const app = createApp(App)

    // Directives
    app.directive('placeholder', placeholder)
    app.directive('white-space', whiteSpace)
    app.directive('safe-html', vSafeHtml)
    app.directive('render-when-children', vRenderWhenChildren)

    // Plugins
    app.use(FloatingVue, {
        themes: {
            [ThemeTooltip.DEFAULT]: {
                triggers: ['hover'],
                autoHide: true,
                $resetCss: true
            },
            [ThemeTooltip.LIGHT]: {
                triggers: ['hover'],
                autoHide: true,
                $resetCss: true
            }
        }
    })
    app.use(VueQueryPlugin, {
        queryClientConfig: {
            defaultOptions: {
                queries: QueryOptions
            }
        }
    } as VueQueryPluginOptions)
    app.use(pinia)
    app.use(i18n)
    app.use(VueNotification)
    if (import.meta.env.VITE_APP_SENTRY_DSN) {
        initSentry({
            app,
            dsn: import.meta.env.VITE_APP_SENTRY_DSN,
            environment: import.meta.env.NODE_ENV,
            ignoreErrors: [
                'Non-Error promise rejection captured',
                'Unable to preload CSS',
                "'text/html' is not a valid JavaScript MIME type"
            ],
            integrations: [
                new BrowserTracing({
                    routingInstrumentation: vueRouterInstrumentation(router),
                    tracingOrigins: [
                        'frenchfounders.com',
                        'private.frenchfounders.com',
                        'api.frenchfounders.com',
                        'app.frenchfounders.com',
                        'pro.frenchfounders.com',
                        'club.frenchfounders.com',
                        'bs.frenchfounders.com'
                    ]
                }),
                new Replay(),
                new CaptureConsole({ levels: ['error'] })
            ],
            trackComponents: true,
            tracesSampleRate: 1.0,
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 0.1 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        })
    }

    if (import.meta.env.VITE_GOOGLE_GTM_TAG_ID) {
        initGoogleAnalytics(import.meta.env.VITE_GOOGLE_GTM_TAG_ID)
    }

    app.use(router)
    app.config.compilerOptions.isCustomElement = (tag: string) => tag.startsWith('swiper')
    app.mount('#app')
})
