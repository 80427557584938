<script setup lang="ts">
    import BaseButton from '@/components/ui/basics/BaseButton.vue'
    import ThemeLayer from '@/components/ui/layers/ThemeLayer.vue'
    import { ThemeName } from '@/vars/ThemeAttr'
    import { useI18n } from 'vue-i18n'
    import { RouteName } from '@/vars/RouteName'
    import { useRoute } from 'vue-router'
    import { computed } from 'vue'
    import BatchHeadband from '@/components/partials/apply/leclub/banners/BatchHeadband.vue'
    import LogoMedia from '@/components/ui/medias/LogoMedia.vue'
    import useBpiApply from '@/views/applies/leclub/partials/compositions/useBpiApply'
    import useOpeTenYears from '@/views/applies/leclub/partials/compositions/useOpeTenYears'
    import ApplyHeaderTenYears from '@/components/layouts/leclub/header/apply/ApplyHeaderTenYears.vue'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'

    type Props = {
        showBatchBanner: boolean
    }

    const theme = ThemeName.LECLUB
    const { t } = useI18n()
    const route = useRoute()
    const { isApplyBpi } = useBpiApply()
    const { isApplyTenYears } = useOpeTenYears()

    const props = computed<Props>(() => {
        const initProps: Props = {
            showBatchBanner: false
        }
        if (route.meta?.layoutProps && typeof route.meta?.layoutProps === 'object') {
            return { ...initProps, ...route.meta?.layoutProps }
        }
        return initProps
    })
    const prevRoute = computed(() => {
        if (route.query.finished === 'true') {
            return false
        }

        switch (route.name) {
            default:
                return undefined
            case RouteName.APPLY_CLUB_PLAN:
                return { name: RouteName.EXPLORE }
            case RouteName.APPLY_LECLUB:
                return { name: RouteName.APPLY_CLUB_PLAN }
        }
    })
</script>

<template>
    <ThemeLayer :theme="theme">
        <div class="apply">
            <BatchHeadband v-if="props?.showBatchBanner" />

            <ApplyHeaderTenYears v-if="isApplyTenYears" />
            <div v-else class="apply__header">
                <div v-if="isApplyBpi" class="apply__logos apply__logos--bpi">
                    <LogoMedia class="apply__logo" dark />
                    <span class="apply__logo-separator"></span>
                    <img class="apply__logo apply__logo--bpi" src="@/assets/images/logos/partners/bpi_excellence.png" />
                </div>
                <template v-else>
                    <div class="apply__mobile">
                        <SpaRouterLink class="apply__arrow" :to="{ name: RouteName.HOME }">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M9.89607 12L17 18.6825L15.5045 20L7 12L15.5045 4L17 5.31749L9.89607 12Z"
                                    fill="#102333"
                                />
                            </svg>
                        </SpaRouterLink>
                    </div>
                    <div class="apply__desktop">
                        <div class="apply__logos">
                            <LogoMedia class="apply__logo" dark />
                        </div>
                        <BaseButton v-if="prevRoute" :to="prevRoute" discret class="apply__button button--discret">
                            {{ t('return') }}
                        </BaseButton>
                    </div>
                </template>
            </div>
            <main>
                <RouterView />
            </main>
        </div>
    </ThemeLayer>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';
    .apply {
        &__header {
            position: relative;
            margin: 2.5em;
        }

        &__logo-separator {
            display: block;
            width: var(--scale-px);
            height: 2.5rem;
            background: var(--theme-border-color);
        }

        &__logos {
            display: inline-flex;
            gap: var(--scale-4r);
            align-self: baseline;

            &--bpi {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &__desktop {
            display: none;
            justify-content: center;
            @media screen and (min-width: $breakpoints-md) {
                justify-content: space-between;
                display: flex;
            }
        }

        &__mobile {
            display: flex;
            justify-content: center;
            @media screen and (min-width: $breakpoints-md) {
                justify-content: space-between;
                display: none;
            }
        }

        &__logo {
            width: 8em;
            height: 100%;

            &--bpi {
                max-width: 6rem;
                aspect-ratio: 1;
            }
        }

        &__arrow {
            position: absolute;
            left: 0;
            top: 0;
            cursor: pointer;
        }

        &__button {
            font-weight: 600;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "logo": "Frenchfounders",
        "return": "Retour aux plans"
    },
    "en": {
        "logo": "Frenchfounders",
        "return": "Return"
    }
}
</i18n>
