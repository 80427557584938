<script setup lang="ts">
    import { ButtonTheme } from '@/vars/ButtonAttr'
    import { ref, watch, onMounted } from 'vue'
    import BaseButton from '../basics/BaseButton.vue'
    import BaseIcon from '../basics/BaseIcon.vue'
    import useAnimation from '@/libs/compositions/useAnimation'

    const props = defineProps<{
        openByDefault?: boolean
    }>()
    const emit = defineEmits<{
        (e: 'open'): void
        (e: 'close'): void
    }>()

    const open = ref(props.openByDefault)
    const contentEl = ref<HTMLDivElement | null>(null)
    const { open: openAnimation, close: closeAnimation } = useAnimation()

    watch(
        () => props.openByDefault,
        () => {
            open.value = props.openByDefault
        }
    )

    watch(open, () => {
        if (!contentEl.value) {
            return null
        }

        if (open.value) {
            openAnimation(contentEl.value, 1)
        } else {
            closeAnimation(contentEl.value, 0.5)
        }
    })

    onMounted(() => {
        if (!contentEl.value) {
            return null
        }

        if (props.openByDefault) {
            openAnimation(contentEl.value, 0)
        } else {
            closeAnimation(contentEl.value, 0)
        }
    })

    const toggle = () => {
        open.value = !open.value
        if (open.value) emit('open')
        else emit('close')
    }
</script>

<template>
    <div class="accordion" :class="{ 'accordion--open': open }">
        <BaseButton :theme="ButtonTheme.DISCRET" space-between block @click="toggle">
            <span class="accordion__header">
                <span class="accordion__header-inner">
                    <slot name="header" v-bind="{ open }"></slot>
                </span>
                <BaseIcon name="expand_less" class="accordion__icon" />
            </span>
        </BaseButton>
        <div ref="contentEl">
            <div class="accordion__body">
                <slot v-bind="{ open }"></slot>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .accordion {
        $block-selector: &;

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        &__icon {
            transition: transform 350ms;
            font-size: 1.25em;

            #{$block-selector}--open & {
                transform: rotate(180deg);
            }
        }
    }
</style>
