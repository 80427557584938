<script setup lang="ts">
    import { computed, ref } from 'vue'
    import { useI18n } from 'vue-i18n'
    import ContainerTemplate from '@/components/templates/ContainerTemplate.vue'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import LogoMedia from '@/components/ui/medias/LogoMedia.vue'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import { AboutRouteName } from '@/vars/RouteName'
    import { SpaName } from '@/vars/SpaAttr'
    import { useAuthStore } from '@/libs/stores/auth.store'
    import AvatarMedia from '@/components/ui/medias/AvatarMedia.vue'
    import MobileMainNavigation from '@/components/layouts/network/headers/public-header/partials/MobileMainNavigation.vue'
    import MobileProfileNavigation from './MobileProfileNavigation.vue'
    import ThemeLayer from '@/components/ui/layers/ThemeLayer.vue'
    import { useTheme } from '@/libs/compositions/useTheme'
    import { StorageKey } from '@/vars/StorageAttr'
    import { AvatarSize } from '@/vars/AvatarAttr'
    import SharedStorage from '@/libs/core/SharedStorage'

    const { t } = useI18n()
    const authStore = useAuthStore()
    const { theme } = useTheme()

    const isNavigationOpened = ref(false)
    const isProfileNavigationOpened = ref(false)

    const currentUniverse = computed(() => {
        switch (import.meta.env.VITE_SPA_RUNNING) {
            case SpaName.LECLUB:
                return t('le_club')
            case SpaName.PRO:
                return t('enterprise')
            default:
                return null
        }
    })

    const onClickLogo = () => {
        SharedStorage.delete(StorageKey.PERSONA_SELECTED)
    }
</script>

<template>
    <div>
        <ContainerTemplate public tag="header" class="header">
            <button type="button" class="header__menu" @click="isNavigationOpened = true">
                <BaseIcon name="burger" />
            </button>
            <SpaRouterLink class="header__link" :to="{ name: AboutRouteName.WEWEB_ABOUT }" @click="onClickLogo">
                <LogoMedia class="header__logo pt-2" ephemeral dark />
            </SpaRouterLink>
            <p v-if="currentUniverse" type="button" class="header__menu header__menu--spaced header__menu--universe">
                {{ currentUniverse }}
                <BaseIcon class="header__menu-expand" name="expand-more" />
            </p>
            <button
                type="button"
                class="header__menu header__menu--account"
                :class="{ 'header__menu--spaced': !currentUniverse }"
                @click="isProfileNavigationOpened = true"
            >
                <AvatarMedia :src="authStore.authentifiedUser?.picture" :size="AvatarSize.MINI" />
            </button>
            <Teleport to="#app">
                <!-- Teleport pour éviter de casser le layout avec les gaps et les éléments invisibles -->
                <ThemeLayer :name="theme">
                    <MobileMainNavigation v-model="isNavigationOpened" class="header__menu-aside" />
                    <MobileProfileNavigation v-model="isProfileNavigationOpened" class="header__menu-aside" />
                </ThemeLayer>
            </Teleport>
        </ContainerTemplate>
    </div>
</template>

<style scoped lang="scss">
    .header {
        display: flex;
        align-items: center;
        gap: var(--scale-6);
        height: 4em;
        color: var(--theme-text-color);

        &__menu-expand {
            font-size: 2em;
        }

        &__menu-aside {
            font-size: 1rem;
        }

        &__avatar-placeholder {
            font-size: var(--avatar-size);
        }

        &__menu {
            padding: var(--scale-1);
            font-size: 2em;
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            background: none;
            border: none;
            color: var(--theme-text-color);

            &--universe {
                border: solid 2px var(--theme-text-color);
                border-radius: 99em;
                font-size: 0.75em;
                font-weight: 500;
                padding: var(--scale-2) var(--scale-4);
                gap: var(--scale-2);
            }

            &--spaced {
                margin-left: auto;
                margin-right: 0;
            }
        }

        &__logo {
            align-self: center;
        }

        &__link {
            display: inline-flex;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "le_club": "Le Club",
        "enterprise": "Entreprise"
    },
    "en": {
        "le_club": "The Club",
        "enterprise": "Enterprise"
    }
}
</i18n>
