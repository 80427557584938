import { Article, Category, Podcast, Resource, Video } from '@/types/Resource.type'
import { ArticlePublishersName, ResourceType } from '@/vars/ResourceAttr'
import axios from 'axios'
import { useAuthStore } from '../stores/auth.store'
import { RoleName } from '@/vars/AuthAttr'

const http = axios.create({
    baseURL: import.meta.env.VITE_STRAPI_BASE_URL
})

export const DEFAULT_PAGINATION_LIMIT = 5

export type RawResourceOptions = {
    ['publishers.slug_in']?: string[]
}

export interface ResourceOptions {
    recommended?: boolean
    page?: number
    limit?: number
    publisher?: string | string[]
    categories?: string[] | string
    type?: ResourceType
    personas?: string[] | string
    or?: ResourceOptions[]
    partners?: string[] | string
}

function sortByPublishedAt(a: Resource, b: Resource) {
    return new Date(b.published_date).getTime() - new Date(a.published_date).getTime()
}

function formatOptions(options: ResourceOptions = {}) {
    const authStore = useAuthStore()

    const params: {
        [key: string]: unknown
    } = { ...options }
    const limit = options?.limit || DEFAULT_PAGINATION_LIMIT

    // Handle option page for pagination
    if (typeof options.page !== 'undefined') {
        params._start = (options.page - 1) * limit
        params._limit = limit
        delete params.page
    }

    // Handle option limit for max items for this call
    if (typeof options.limit !== 'undefined') {
        params._limit = limit
        delete params.limit
    }

    if (typeof options.or !== 'undefined') {
        params._or = options.or.map((option) => {
            const resultSearch = formatOptionsSearch(option)

            resultSearch.deletedKey.forEach((key) => {
                delete option[key]
            })

            Object.keys(resultSearch.params).forEach((key) => {
                option[key] = resultSearch.params[key]
            })
            return option
        })
        delete params.or
    } else {
        const resultSearch = formatOptionsSearch(params)

        resultSearch.deletedKey.forEach((key) => {
            delete params[key]
        })

        Object.keys(resultSearch.params).forEach((key) => {
            params[key] = resultSearch.params[key]
        })
    }

    if (!authStore.isLoggedIn || !authStore.hasRole(RoleName.ADMIN)) {
        params['published_date_lte'] = new Date().toISOString()
    }

    params['_sort'] = 'published_date:DESC'
    delete params.type

    return params
}

function formatOptionsSearch(options: ResourceOptions): { params: ResourceOptions; deletedKey: string[] } {
    const params: {
        [key: string]: unknown
    } = { ...options }
    const deletedKey: string[] = []

    // Handle filter publish
    if (typeof options.publisher === 'undefined') {
        // If publisher is not defined, we filter by publisher Frenchfounders public & Community
        params['publishers.name_in'] = [
            ArticlePublishersName.NETWORK,
            ArticlePublishersName.PUBLIC,
            ArticlePublishersName.LES_TALENTS,
            ArticlePublishersName.IC
        ]
    } else {
        params['publishers.name_in'] = typeof options.publisher === 'string' ? [options.publisher] : options.publisher
        delete params.publisher
        deletedKey.push('publisher')
    }

    if (options.categories !== null) {
        params['category.name_contains'] = options.categories
        delete params.categories
        deletedKey.push('categories')
    }
    if (typeof options.personas !== 'undefined') {
        params['personas.name_contains'] = options.personas
        delete params.personas
        deletedKey.push('personas')
    }
    if (options.partners !== null) {
        params['inline_partners_contains'] = options.partners
        delete params.partners
    }

    return { params: params, deletedKey: deletedKey }
}

export class ResourceApi {
    static async getCategories(): Promise<Category[]> {
        const response = await http.get('categories')

        return response.data
    }

    static async getResourceList(options?: ResourceOptions): Promise<Resource[]> {
        switch (options?.type) {
            // Merge all resources
            case undefined: {
                const resources: [Article[], Video[], Podcast[]] = await Promise.all([
                    ResourceApi.getArticleList(options),
                    ResourceApi.getVideoList(options),
                    ResourceApi.getPodcastList(options)
                ])

                return resources.flat().slice().sort(sortByPublishedAt)
            }

            case ResourceType.ARTICLE:
                return await ResourceApi.getArticleList(options)
            case ResourceType.VIDEO:
                return await ResourceApi.getVideoList(options)
            case ResourceType.PODCAST:
                return await ResourceApi.getPodcastList(options)
            default:
                throw new Error('[recent] Unknown type')
        }
    }

    static async getArticleList(options?: ResourceOptions | RawResourceOptions): Promise<Article[]> {
        const response = await http.get('articles', {
            params: options && 'publishers.slug_in' in options ? options : formatOptions(options as ResourceOptions)
        })

        return response.data
    }

    static async getCategoriesArticles(slug: string): Promise<Article[]> {
        const response = await http.get(`articles?category.name=${slug}`)

        return response.data
    }

    static async getSingleArticle(slug: string): Promise<Article> {
        const response = await http.get(`articles/${slug}`)

        return response.data
    }

    static async getVideoList(options?: ResourceOptions): Promise<Video[]> {
        const response = await http.get('videos', {
            params: formatOptions(options)
        })

        return response.data
    }
    static async getCategoriesVideo(categories: string): Promise<Video[]> {
        const response = await http.get(`videos?_start=0&_limit=5${categories}`)

        return response.data
    }
    static async getSingleVideo(slug: string): Promise<Video> {
        const response = await http.get(`videos/${slug}`)

        return response.data
    }

    static async getPodcastList(options?: ResourceOptions): Promise<Podcast[]> {
        const response = await http.get('podcasts', {
            params: formatOptions(options)
        })

        return response.data
    }
    static async getCategoriesPodcast(categories: string): Promise<Podcast[]> {
        const response = await http.get(`podcasts?_start=0&_limit=5${categories}`)

        return response.data
    }

    static async getSinglePodcast(slug: string): Promise<Podcast> {
        const response = await http.get(`podcasts/${slug}`)

        return response.data
    }
}

export default ResourceApi
