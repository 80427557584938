import {
    requiredAuthentification,
    requireProOnboarding,
    requireTeam,
    requireTeamRole,
    requireTeamWithRedirection
} from '@/libs/middlewares/auth'
import { AboutRouteName, ProRouteName } from '@/vars/RouteName'
import { RouteRecordRaw } from 'vue-router'
import EmptyLayout from '@/components/layouts/EmptyLayout.vue'
import AsideLayout from '@/components/layouts/pro/AsideLayout.vue'
import { TeamRole } from '@/types/TeamRole.type'
import { useAuthStore } from '@/libs/stores/auth.store'
import aboutRoutes from './common/about-routes'
import i18n from '@/i18n'

const routes: RouteRecordRaw[] = [
    ...aboutRoutes,
    {
        path: '/teams/:teamId/join/:invitationCode',
        name: ProRouteName.TEAM_JOIN,
        component: () => import('@/views/pro/teams/team-join/TeamJoin.vue'),
        meta: {
            middleware: [requiredAuthentification]
        }
    },
    {
        path: '/',
        name: ProRouteName.HOME,
        component: () => import('@/views/pro/overview/OverviewPage.vue'),
        meta: {
            middleware: [requiredAuthentification, requireTeam, requireProOnboarding]
        }
    },
    {
        path: '/jobs',
        name: ProRouteName.JOBS,
        component: () => import('@/views/pro/jobs/HomeJobs/HomeJobs.vue'),
        redirect: {
            name: ProRouteName.JOBS_ACTIVE
        },
        meta: {
            layout: AsideLayout,
            middleware: [requiredAuthentification, requireTeam, requireProOnboarding]
        },
        children: [
            {
                path: 'all',
                name: ProRouteName.JOBS_ALL,
                //component: () => import('@/views/pro/jobs/HomeJobs/tabs/AllOffers.vue'),
                redirect: {
                    name: ProRouteName.JOBS_ACTIVE
                },
                meta: {
                    middleware: [requiredAuthentification, requireTeam, requireProOnboarding]
                }
            },
            {
                path: 'active',
                name: ProRouteName.JOBS_ACTIVE,
                component: () => import('@/views/pro/jobs/HomeJobs/tabs/ActiveOffers.vue'),
                meta: {
                    middleware: [requiredAuthentification, requireTeam, requireProOnboarding]
                }
            },
            {
                path: 'archived',
                name: ProRouteName.JOBS_ARCHIVED,
                component: () => import('@/views/pro/jobs/HomeJobs/tabs/ArchivedOffers.vue'),
                meta: {
                    middleware: [requiredAuthentification, requireTeam, requireProOnboarding]
                }
            },
            {
                path: 'drafts',
                name: ProRouteName.JOBS_DRAFTS,
                component: () => import('@/views/pro/jobs/HomeJobs/tabs/DraftOffers.vue'),
                meta: {
                    middleware: [requiredAuthentification, requireTeam, requireProOnboarding]
                }
            }
        ]
    },
    {
        path: '/candidates/:jobId/details/:candidateId',
        name: ProRouteName.JOB_CANDIDATE_SINGLE,
        component: () => import('@/views/pro/candidates/single/CandidateSinglePage.vue'),
        meta: {
            layout: AsideLayout,
            middleware: [requiredAuthentification, requireTeam, requireProOnboarding]
        }
    },
    {
        path: '/candidates/:jobId',
        component: () => import('@/views/pro/candidates/list/CandidateListPage.vue'),
        meta: {
            layout: AsideLayout,
            middleware: [requiredAuthentification, requireTeam, requireProOnboarding]
        },
        children: [
            {
                path: ':status?',
                name: ProRouteName.JOB_CANDIDATES,
                component: () => import('@/views/pro/candidates/list/__partials__/CandidateTab.vue'),
                meta: {
                    middleware: [requiredAuthentification, requireTeam, requireProOnboarding]
                }
            }
        ]
    },
    {
        path: '/business-connections',
        name: ProRouteName.BUSINESS_CONNECTIONS,
        component: () => import('@/views/pro/business-connections/BusinessConnections.vue'),
        meta: {
            layout: AsideLayout,
            middleware: [requiredAuthentification, requireTeam, requireProOnboarding]
        }
    },
    {
        path: '/fundraising',
        name: ProRouteName.FUNDRAISING,
        component: () => import('@/views/pro/fundraising/FundraisingPage.vue'),
        meta: {
            layout: AsideLayout,
            middleware: [requiredAuthentification, requireTeam, requireProOnboarding]
        }
    },
    {
        path: '/company-profile',
        name: ProRouteName.MY_COMPANY_PROFILE,
        component: () => import('@/views/pro/company-profile/CompanyProfile.vue'),
        meta: {
            layout: AsideLayout,
            middleware: [
                requiredAuthentification,
                requireTeam,
                requireProOnboarding,
                requireTeamRole([TeamRole.ROLE_TEAM_OWNER, TeamRole.ROLE_TEAM_ADMIN])
            ]
        }
    },
    {
        path: '/events',
        name: ProRouteName.EVENTS,
        component: () => import('@/views/pro/events/EventsPage.vue'),
        meta: {
            layout: AsideLayout,
            middleware: [requiredAuthentification, requireTeam, requireProOnboarding]
        }
    },
    {
        path: '/admin',
        name: ProRouteName.ADMIN,
        meta: {
            layout: AsideLayout,
            middleware: [requiredAuthentification, requireTeam, requireProOnboarding],
            openSideMenu: true,
            navigation: [
                {
                    routeName: ProRouteName.MY_COMPANY_PROFILE,
                    label: i18n.global.t('router.tab.company_profile'),
                    iconV2: 'badge'
                },
                {
                    routeName: ProRouteName.ADMIN_MEMBERSHIPS,
                    label: i18n.global.t('router.tab.memberships_club'),
                    iconV2: 'group_add'
                },
                {
                    routeName: ProRouteName.CONTRACTS_MEMBERSHIPS,
                    label: i18n.global.t('router.tab.contracts_and_memberships'),
                    iconV2: 'credit_card',
                    activeRoutes: [
                        ProRouteName.CONTRACTS_MEMBERSHIPS_AND_INVOICES,
                        ProRouteName.CONTRACTS_MEMBERSHIPS_COMPANY_INFORMATIONS
                    ]
                },
                {
                    routeName: ProRouteName.SETTINGS,
                    label: i18n.global.t('router.tab.manage_team'),
                    iconV2: 'groups'
                }
            ]
        },

        children: [
            {
                path: 'contracts-and-memberships',
                name: ProRouteName.CONTRACTS_MEMBERSHIPS,
                component: () => import('@/views/pro/admin/contract-and-memberships/ContractAndMembershipsPage.vue'),
                meta: {
                    layout: AsideLayout,
                    middleware: [
                        requiredAuthentification,
                        requireTeam,
                        requireProOnboarding,
                        requireTeamRole([TeamRole.ROLE_TEAM_OWNER])
                    ]
                },
                redirect: {
                    name: ProRouteName.CONTRACTS_MEMBERSHIPS_AND_INVOICES
                },
                children: [
                    {
                        path: 'infos',
                        name: ProRouteName.CONTRACTS_MEMBERSHIPS_AND_INVOICES,
                        component: () =>
                            import(
                                '@/views/pro/admin/contract-and-memberships/tabs/memberships-and-invoices/MembershipsAndInvoices.vue'
                            )
                    },
                    {
                        path: 'company-informations',
                        name: ProRouteName.CONTRACTS_MEMBERSHIPS_COMPANY_INFORMATIONS,
                        component: () =>
                            import(
                                '@/views/pro/admin/contract-and-memberships/tabs/my-company-informations/MyCompanyInformations.vue'
                            )
                    }
                ]
            },
            {
                path: 'roles',
                alias: '/settings/roles',
                name: ProRouteName.SETTINGS,
                meta: {
                    layout: AsideLayout,
                    middleware: [
                        requiredAuthentification,
                        requireTeam,
                        requireProOnboarding,
                        requireTeamRole([TeamRole.ROLE_TEAM_OWNER, TeamRole.ROLE_TEAM_ADMIN])
                    ]
                },
                component: () => import('@/views/pro/my-settings/role-management/RoleManagement.vue')
            },
            {
                path: 'club-memberships',
                name: ProRouteName.ADMIN_MEMBERSHIPS,
                meta: {
                    layout: AsideLayout,
                    middleware: [requiredAuthentification, requireTeam, requireProOnboarding]
                },
                component: () => import('@/views/pro/admin/club-memberships/YourCurrentMemberships.vue')
            },
            {
                path: 'company-profile',
                alias: '/company-profile',
                name: ProRouteName.MY_COMPANY_PROFILE,
                component: () => import('@/views/pro/company-profile/CompanyProfile.vue'),
                meta: {
                    layout: AsideLayout,
                    middleware: [
                        requiredAuthentification,
                        requireTeam,
                        requireProOnboarding
                        // requireTeamRole([TeamRole.ROLE_TEAM_OWNER, TeamRole.ROLE_TEAM_ADMIN]),
                    ]
                }
            }
        ]
    },
    {
        path: '/welcome',
        name: ProRouteName.ONBOARDING,
        redirect: { name: ProRouteName.ONBOARDING }
    },
    {
        path: '/onboarding',
        component: () => import('@/views/pro/onboarding/OnboardingPage.vue'),
        meta: {
            layout: EmptyLayout,
            middleware: [requiredAuthentification, requireTeam]
        },
        children: [
            {
                path: '',
                name: ProRouteName.ONBOARDING,
                component: () => import('@/views/pro/onboarding/step-company/OnboardingStepCompany.vue')
            },
            {
                path: 'challenges',
                name: ProRouteName.ONBOARDING_CHALLENGES,
                component: () => import('@/views/pro/onboarding/step-challenges/OnboardingStepChallenges.vue')
            },
            {
                path: 'invitations',
                name: ProRouteName.ONBOARDING_INVITE,
                component: () => import('@/views/pro/onboarding/step-invite/OnboardingStepInvite.vue')
            },
            {
                path: 'complete',
                name: ProRouteName.ONBOARDING_COMPLETE,
                component: () => import('@/views/pro/onboarding/step-complete/OnboardingStepComplete.vue')
            }
        ]
    },
    {
        path: '/new-job',
        name: ProRouteName.JOB_NEW,
        component: () => import('@/views/pro/jobs/JobEdit/JobEdit.vue'),

        meta: {
            middleware: [requiredAuthentification, requireTeamWithRedirection /*, requireProOnboarding*/]
        }
    },
    {
        path: '/company/apply/:planKey',
        name: ProRouteName.COMPANY_APPLY,
        component: () => import('@/views/pro/company/apply/CompanyApply.vue'),
        meta: {
            layout: EmptyLayout
        },
        beforeEnter: () => {
            const authStore = useAuthStore()
            if (authStore.decodedToken?.finishedOnboarding?.pro) {
                return { name: ProRouteName.MY_COMPANY_PROFILE }
            }

            return true
        },
        redirect: (to) => {
            return {
                name: ProRouteName.COMPANY_APPLY_NEW_ACCOUNT,
                params: { planKey: to.params.planKey }
            }
        },
        children: [
            {
                name: ProRouteName.COMPANY_APPLY_NEW_ACCOUNT,
                path: 'new-account',
                component: () => import('@/views/pro/company/apply/new-account/NewAccount.vue'),
                beforeEnter: (to) => {
                    const { isLoggedIn } = useAuthStore()

                    if (isLoggedIn) {
                        return {
                            name: ProRouteName.COMPANY_APPLY_ABOUT_COMPANY,
                            params: { planKey: to.params.planKey }
                        }
                    }

                    return true
                }
            },
            {
                name: ProRouteName.COMPANY_APPLY_ABOUT_COMPANY,
                path: 'about-company',
                component: () => import('@/views/pro/company/apply/about-company/AboutCompany.vue'),
                beforeEnter: (to) => {
                    const { isLoggedIn } = useAuthStore()

                    if (!isLoggedIn) {
                        return { name: ProRouteName.COMPANY_APPLY_NEW_ACCOUNT, params: { planKey: to.params.planKey } }
                    }

                    return true
                }
            },
            {
                name: ProRouteName.COMPANY_APPLY_PAYMENT,
                path: 'payment',
                component: () => import('@/views/pro/company/apply/payment/ApplyPayment.vue'),
                beforeEnter: (to) => {
                    const { isLoggedIn } = useAuthStore()

                    if (!isLoggedIn) {
                        return { name: ProRouteName.COMPANY_APPLY_NEW_ACCOUNT, params: { planKey: to.params.planKey } }
                    }

                    return true
                },
                meta: {
                    middleware: [requireTeam]
                }
            }
        ]
    },
    {
        path: '/cgv',
        name: ProRouteName.CGV,
        redirect: {
            name: AboutRouteName.WEWEB_CGV_ENTREPRISE
        }
    },
    {
        path: '/team-already-existing',
        name: ProRouteName.TEAM_ALREADY_EXISTING,
        component: () => import('@/views/pro/teams/team-already-existing/TeamAlreadyExisting.vue'),
        meta: {
            middleware: [requiredAuthentification]
        }
    },
    {
        path: '/jobs/edit/:jobId',
        name: ProRouteName.JOB_EDIT,
        component: () => import('@/views/pro/jobs/JobEdit/JobEdit.vue'),
        meta: {
            middleware: [requiredAuthentification, requireTeam, requireProOnboarding]
        }
    },
    {
        path: '/404',
        name: ProRouteName.NOT_FOUND,
        component: () => import('@/views/NotFoundPage.vue'),
        meta: {
            layout: EmptyLayout
        }
    },
    {
        path: '/subscriptions',
        redirect: {
            name: ProRouteName.ADMIN_MEMBERSHIPS
        }
    },
    {
        path: '/admin/cancel-subscription/:subscriptionId',
        name: ProRouteName.CANCEL_SUBSCRIPTION,
        meta: {
            middleware: [requiredAuthentification, requireTeam]
        },
        redirect(to) {
            return { ...to, name: ProRouteName.CHANGE_SUBSCRIPTION }
        }
    },
    {
        path: '/admin/change-subscription/:subscriptionId/:selectedPlan?',
        name: ProRouteName.CHANGE_SUBSCRIPTION,
        component: () => import('@/views/pro/admin/subscription/modify-subscription/ChangeSubscriptionPage.vue'),
        meta: {
            middleware: [requiredAuthentification, requireTeam]
        }
    },
    {
        path: '/business-directory/plans',
        name: ProRouteName.BUSINESS_DIRECTORY_PLANS,
        component: () => import('@/views/pro/business-directory-plans/BusinessDirectoryPlansPage.vue')
    },
    {
        // Redirection pour les anciennes routes
        path: '/company/apply',
        redirect: {
            name: ProRouteName.BUSINESS_DIRECTORY_PLANS
        }
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: () => ({
            name: ProRouteName.NOT_FOUND
        })
    }
]

export default routes
