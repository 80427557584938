<script setup lang="ts">
    import BaseButton from '@/components/ui/basics/BaseButton.vue'
    import BaseModal from '@/components/ui/basics/BaseModal.v2.vue'
    import FormInput from '@/components/ui/forms/FormInput.vue'
    import { ref, watch } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { ButtonTheme } from '@/vars/ButtonAttr'
    import { useField, useForm } from 'vee-validate'
    import { useMutation } from '@tanstack/vue-query'
    import AuthApi from '@/libs/api/Auth.api'
    import { useAuthStore } from '@/libs/stores/auth.store'
    import useNotification from '@/libs/compositions/useNotification'
    import { ToastType } from '@/vars/ToastAttr'
    import FormCheckboxV2 from '@/components/ui/forms/FormCheckbox.v2.vue'
    import AuthSocial from './partials/AuthSocial.vue'
    import FormError from '@/components/ui/forms/FormError.vue'
    import { captureException } from '@sentry/core'
    import { signupUserInGoogleAnalytics } from '@/libs/vendors/google/analytics/google-analytics'
    import { AxiosError } from 'axios'
    import SharedStorage from '@/libs/core/SharedStorage'
    import { StorageKey } from '@/vars/StorageAttr'
    import { ResponseFormError } from '@/types/response/ResponseV3.type'
    import { useRoute } from 'vue-router'
    import { useSegment } from '@/libs/vendors/segment/segment'
    import { RouteName } from '@/vars/RouteName'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import FormPasswordInput from '@/components/ui/forms/FormPasswordInput.vue'

    const { analytics } = useSegment()

    type Form = {
        firstName: string
        lastName: string
        email: string
        cgu: boolean
        password: string
        passwordConfirmation: string
    }

    const { t } = useI18n()

    const props = withDefaults(
        defineProps<{
            modelValue: boolean
            title?: string
            buttonText?: string
            from?: string
            forceLoading?: boolean
            skipRegister?: boolean // Permet d'éviter de faire l'appel au register (notamment quand on réessaie de faire une requête)
        }>(),
        {
            title: '',
            buttonText: '',
            from: 'global'
        }
    )

    watch(
        () => props.modelValue,
        () => {
            if (props.modelValue) {
                analytics.track('community:register:modal-opened', {
                    from: props.from
                })
            }
        }
    )

    const emit = defineEmits<{
        (e: 'update:modelValue', value: boolean): void
        (e: 'success', value): void
    }>()

    const route = useRoute()
    const store = useAuthStore()
    const { toast } = useNotification()
    const { meta, values, handleSubmit } = useForm()
    const { setErrors: setErrorsEmail, errorMessage: errorMessageEmail } = useField('email', undefined, {
        syncVModel: false
    })

    const { mutate, isPending, error } = useMutation<
        Awaited<ReturnType<typeof AuthApi.register>>,
        AxiosError<ResponseFormError<Form>>
    >({
        mutationFn: () => {
            const publicParticipantId =
                (route.query.publicParticipantId as string) ||
                SharedStorage.get(StorageKey.PUBLIC_PARTICIPANT_ID) ||
                undefined
            return AuthApi.register({
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                password: values.password,
                suw: 'password',
                context: '',
                publicParticipantId: publicParticipantId || undefined,
                persona: SharedStorage.get(StorageKey.PERSONA_SELECTED) || undefined
            })
        },
        onSuccess: async (registerData) => {
            store.updateTokens(registerData.meta.jwt.token, registerData.meta.jwt.refreshToken)
            await store.getMe()

            if (store?.authentifiedUser?.idff) {
                signupUserInGoogleAnalytics(store?.authentifiedUser?.idff, props.from)

                analytics.track('community:register:modal-submitted', {
                    from: props.from
                })
            }

            afterSuccessRegister()
        },
        onError: (e: AxiosError) => {
            console.error(e)
            if (e.response?.status === 409) {
                toast({
                    type: ToastType.ERROR,
                    text: t('error.account_already_exists_with_email.text'),
                    duration: 5_000
                })
            } else {
                captureException(e)
                toast({
                    type: ToastType.ERROR,
                    text: t('error_generic'),
                    duration: 5_000
                })
            }
        }
    })

    const pendingOAuth = ref(false)
    const errorMessage = ref('')

    // * uniquement pour AuthSocial
    function onErrors(error: { status: number; message: string | string[] }) {
        console.error(error)
        captureException(error)
        pendingOAuth.value = false
        if (error.status === 409) {
            setErrorsEmail(error.message as string)
        } else {
            errorMessage.value = error.message as string
        }
    }

    const afterSuccessRegister = handleSubmit((formValues) => {
        const formRegisterValues = formValues
        delete formRegisterValues?.password
        emit('success', formRegisterValues)
    })
</script>

<template>
    <BaseModal :model-value="modelValue" @update:model-value="$emit('update:modelValue', $event)">
        <template #title>{{ title || t('title') }}</template>
        <slot name="subtitle">{{ t('subtitle') }}</slot>
        <slot name="description">
            <div>
                <a href="https://frenchfounders.com" class="modal__link">{{ t('know_more_ff') }}</a>
            </div>
        </slot>
        <div class="modal__form">
            <div class="modal__form--flex mt-4">
                <FormInput
                    name="firstName"
                    rules="required"
                    placeholder="Camille"
                    :error="error?.response?.data?.errors?.firstName?.[0]?.message"
                    >{{ t('form.firstname') }}</FormInput
                >
                <FormInput
                    name="lastName"
                    rules="required"
                    placeholder="Dupont"
                    :error="error?.response?.data?.errors?.lastName?.[0]?.message"
                    >{{ t('form.lastname') }}</FormInput
                >
            </div>
            <FormInput
                class="mt-4"
                name="email"
                rules="required"
                placeholder="camille.dupont@gmail.com"
                :error="errorMessageEmail || error?.response?.data?.errors?.email?.[0]?.message"
            >
                {{ t('form.email') }}
            </FormInput>
            <div class="modal__form--flex mt-4">
                <FormPasswordInput
                    name="password"
                    placeholder="**********"
                    :error="error?.response?.data?.errors?.password?.[0]?.message"
                >
                    {{ t('form.password') }}
                </FormPasswordInput>
                <FormInput
                    type="password"
                    name="passwordConfirmation"
                    show-eye
                    rules="required|confirmed:@password"
                    placeholder="**********"
                    :error="error?.response?.data?.errors?.passwordConfirmation?.[0]?.message"
                >
                    {{ t('form.confirm_password') }}
                </FormInput>
            </div>
            <slot name="form"></slot>
            <FormCheckboxV2 name="cgu" rules="required" class="mt-4">
                <div v-safe-html="t('cgu')"></div>
            </FormCheckboxV2>
            <div class="modal__form--submit mt-4">
                <BaseButton
                    :theme="ButtonTheme.PRIMARY"
                    :pending="isPending || pendingOAuth || forceLoading"
                    :disabled="!meta.valid && !pendingOAuth"
                    @click="skipRegister ? afterSuccessRegister() : mutate()"
                >
                    {{ buttonText || t('button') }}
                </BaseButton>
            </div>
        </div>
        <FormError v-if="errorMessage">
            {{ errorMessage }}
        </FormError>
        <AuthSocial
            v-if="!$slots.form"
            type="register"
            @error="onErrors"
            @success="afterSuccessRegister"
            @pending="pendingOAuth = true"
        />
        <div class="modal__already-register mt-4">
            <SpaRouterLink :to="{ name: RouteName.LOGIN }">
                {{ t('already_register') }}
            </SpaRouterLink>
        </div>
    </BaseModal>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';

    .modal {
        &__link {
            text-decoration: underline;
        }

        &__form {
            &--flex {
                @media screen and (min-width: $breakpoints-md) {
                    display: grid;
                    gap: var(--scale-4);
                    grid-template-columns: 1fr 1fr;
                }
            }
            &--submit {
                text-align: center;
            }
        }

        &__already-register {
            text-align: center;
            text-decoration: underline;
            cursor: pointer;
            font-weight: 600;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "title": "Rejoignez le réseau Frenchfounders gratuitement",
        "subtitle": "Inscrivez-vous ci-dessous pour rejoindre Frenchfounders.",
        "know_more_ff": "En savoir plus sur Frenchfounders",
        "form": {
            "firstname": "Prénom",
            "lastname": "Nom",
            "email": "Adresse email",
            "password": "Créez un mot de passe",
            "confirm_password": "Confirmez le mot de passe"
        },
        "button": "Rejoindre le réseau Frenchfounders",
        "cgu": "J'accepte les <a style='text-decoration: underline;' href='https://www.frenchfounders.com/page/cgv/' target='_blank'>CGU</a>",
        "error_generic": "Une erreur s'est produite, veuillez réessayer ultérieurement.",
        "already_register": "Déjà inscrit sur Frenchfounders ? Connectez-vous !"
    },
    "en": {
        "title": "I join Frenchfounders",
        "subtitle": "Register below to join Frenchfounders.",
        "know_more_ff": "Learn more about Frenchfounders",
        "form": {
            "firstname": "First name",
            "lastname": "Last name",
            "email": "E-mail address",
            "password": "Create a password",
            "confirm_password": "Confirm the password"
        },
        "button": "I join the Frenchfounders network",
        "cgu": "I accept the <a style='text-decoration: underline;' href='https://www.frenchfounders.com/page/cgv/' target='_blank'>terms</a>",
        "error_generic": "An error has occurred, please try again later.",
        "already_register": "Already registered on Frenchfounders ? Connect !"
    }
}
</i18n>
