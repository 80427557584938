export enum AvatarSize {
    MINI = 'mini',
    SMALL = 'small',
    NORMAL = 'normal',
    LARGE = 'large'
}

export enum AvatarListTheme {
    DEFAULT = 'default',
    CLUB = 'club'
}
