<script setup lang="ts">
    import BaseButton from '@/components/ui/basics/BaseButton.vue'
    import ThemeLayer from '@/components/ui/layers/ThemeLayer.vue'
    import { ThemeName } from '@/vars/ThemeAttr'
    import { useI18n } from 'vue-i18n'
    import { RouteName } from '@/vars/RouteName'
    import { computed, ref } from 'vue'
    import useCssColor from '@/libs/helpers/useCssColor'
    import { useAuthStore } from '@/libs/stores/auth.store'
    import { RoleName } from '@/vars/AuthAttr'
    import { useRouter } from 'vue-router'
    import LogoMedia from '../ui/medias/LogoMedia.vue'
    import ContainerTemplate from '../templates/ContainerTemplate.vue'
    import { ButtonTheme } from '@/vars/ButtonAttr'
    import { useRoute } from 'vue-router'

    type Props = {
        backLink?: (typeof RouteName)[keyof typeof RouteName]
    }

    const theme = ThemeName.PRO
    const { t } = useI18n()
    const router = useRouter()
    const route = useRoute()
    const { hasRole, isLostClub, authentifiedUser } = useAuthStore()

    const accountEl = ref<HTMLDivElement | null>(null)
    const { getCssColorValue } = useCssColor(accountEl)
    const privateUrl = import.meta.env.VITE_PRIVATE_BASE_URL

    const fromClub = computed(() => {
        return hasRole(RoleName.LECLUB)
    })

    const isLost = computed(() => {
        return isLostClub && !authentifiedUser?.finishedOnboarding?.community
    })

    const props = computed<Props>(() => {
        const initProps: Props = {
            backLink: undefined
        }
        if (route.meta?.layoutProps && typeof route.meta?.layoutProps === 'object') {
            return { ...initProps, ...route.meta?.layoutProps }
        }
        return initProps
    })

    const onClickReturn = () => {
        if (isLost.value) {
            return
        }

        if (props.value.backLink) {
            router.push({
                name: props.value.backLink
            })
        } else {
            if (window.ReactNativeWebView) {
                window.location.href = 'frenchfounders://go-back/' //deeplink who make a go back on app
            } else if (fromClub.value) {
                window.location.href = privateUrl
            } else {
                router.push({
                    name: RouteName.HOME
                })
            }
        }
    }

    const onClickLogo = () => {
        router.push({
            name: RouteName.HOME
        })
    }
</script>

<template>
    <ThemeLayer :theme="theme">
        <ContainerTemplate>
            <div ref="accountEl" class="account">
                <div class="account__header">
                    <div class="account__mobile">
                        <Component
                            :is="fromClub ? 'a' : 'RouterLink'"
                            v-if="!isLost"
                            to="#"
                            class="account__arrow"
                            @click="onClickReturn"
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M9.89607 12L17 18.6825L15.5045 20L7 12L15.5045 4L17 5.31749L9.89607 12Z"
                                    :fill="getCssColorValue('--theme-text-color')"
                                />
                            </svg>
                        </Component>
                        <LogoMedia class="account__logo" dark short @click="onClickLogo" />
                    </div>
                    <div class="account__desktop">
                        <LogoMedia class="account__logo" dark @click="onClickLogo" />
                        <BaseButton
                            v-if="!isLost"
                            :theme="ButtonTheme.DISCRET"
                            class="account__button"
                            @click="onClickReturn"
                        >
                            {{ t('return') }}
                        </BaseButton>
                    </div>
                </div>
                <main>
                    <RouterView />
                </main>
            </div>
        </ContainerTemplate>
    </ThemeLayer>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';
    .account {
        &__header {
            position: relative;
            margin: 2.5em;
        }

        &__desktop {
            display: none;
            justify-content: center;
            @media screen and (min-width: $breakpoints-md) {
                justify-content: space-between;
                display: flex;
            }
        }

        &__mobile {
            display: flex;
            justify-content: center;
            @media screen and (min-width: $breakpoints-md) {
                justify-content: space-between;
                display: none;
            }
        }

        &__logo {
            width: 5em;
            height: 100%;
            max-height: 3.5em;
            object-fit: contain;
            cursor: pointer;

            @media screen and (min-width: 23.438em) {
                width: 10em;
            }
        }

        &__arrow {
            position: absolute;
            left: 0;
            top: 0;
            cursor: pointer;
        }

        &__button {
            font-weight: 600;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "logo": "Frenchfounders",
        "return": "Retour"
    },
    "en": {
        "logo": "Frenchfounders",
        "return": "Back"
    }
}
</i18n>
