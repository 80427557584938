import { BubbleMeta } from '@/types/API.type'
import { Candidate, CandidateStatus } from '@/types/Candidate.type'
import { DeactivateJobReasonForm } from '@/types/forms/jobs-api/DeactivateJobReason.type'
import { EditJob } from '@/types/forms/jobs-api/EditJob.type'
import { GetAllJobsForm } from '@/types/forms/jobs-api/GetAllJobsForm.type'
import {
    Job,
    JobCandidate,
    JobCandidateForm,
    JobCandidateSearchForm,
    JobConfiguration,
    JobSingle,
    JobLastViews
} from '@/types/Job.type'
import { GetJob } from '@/types/response/jobs-api/GetJob.type'
import { RecruiterJob, RecruiterJobStatus } from '@/types/response/jobs-api/RecruiterJob.type'
import { ResponseV3, MetaPager } from '@/types/response/ResponseV3.type'
import { SelectedCandidateType } from '@/types/response/SelectedCandidate.type'
import { stringifyLocations } from '../helpers/stringify-locations'
import Http from './ApiClient'

interface JobsOptions {
    page?: number
    status?: string
    limit?: number
}
class JobsApi {
    static async getJobs(options: JobsOptions): Promise<ResponseV3<Job[], MetaPager>> {
        const response = await Http.get('/v3/hr/jobs/board', {
            params: options
        })

        return response.data
    }

    /**
     * Liste des jobs consultés récemment par l'utilisateur connecté
     */
    static async getJobVisitedHistory(params?: {
        limit?: number
        skipSelected?: boolean
    }): Promise<ResponseV3<Job[], MetaPager>> {
        const response = await Http.get('/v3/hr/jobs/history', {
            params
        })

        return response.data
    }

    static async getAllJobs(params: GetAllJobsForm): Promise<ResponseV3<Job[], MetaPager>> {
        const response = await Http.get('/v3/hr/jobs', {
            params
        })

        return response.data
    }

    static async getJobSingle(id: string): Promise<ResponseV3<JobSingle, BubbleMeta>> {
        const response = await Http.get(`/v3/hr/jobs/${id}`)

        return response.data
    }

    static async getMyJobList(
        params?: {
            status?: RecruiterJobStatus
            createdBy?: string | null
        },
        page?: number,
        limit?: number
    ): Promise<ResponseV3<RecruiterJob[], MetaPager>> {
        const response = await Http.get(`/v3/hr/jobs/my-jobs`, {
            params: {
                status: params?.status,
                createdBy: params?.createdBy,
                page,
                limit
            }
        })

        return response.data
    }

    static async deactivateJob(id: string, form: DeactivateJobReasonForm) {
        return await Http.post(`/v3/hr/jobs/${id}/deactivate`, form)
    }

    static async activateJobOffer(id: string) {
        await Http.post(`/v3/hr/jobs/${id}/activate`)
    }

    static async apply(id: string, comment: string, linkedinUrl?: string) {
        await Http.post(`/v3/hr/jobs/${id}/apply`, { comment, linkedinUrl })
    }

    static async decline(id: string) {
        await Http.post(`/v3/hr/jobs/${id}/decline`)
    }

    static async hire(id: string, hired: boolean) {
        await Http.put(`/v3/hr/jobs/${id}/hiring`, { hired })
    }

    static async getCandidateConfiguration(): Promise<ResponseV3<JobConfiguration, null>> {
        const response = await Http.get('/v3/hr/configuration')

        return response.data
    }

    static async getCandidateProfile(): Promise<ResponseV3<JobCandidate, null>> {
        const response = await Http.get('/v3/hr/candidates/me?format=raw')

        return response.data
    }

    static async updateCandidateProfile(candidate: JobCandidateForm) {
        await Http.patch(`/v3/hr/candidates/me`, stringifyLocations(candidate))
    }

    static async uploadLinkedinResume(resume: { linkedinCv?: string | ArrayBuffer | null; linkedinUrl?: string }) {
        await Http.patch(`/v3/hr/candidates/me/linkedin-cv`, resume)
    }

    static async getLinkedinResume(): Promise<ResponseV3<string | ArrayBuffer | null>> {
        const response = await Http.get(`/v3/hr/candidates/me/linkedin-cv`)

        return response.data
    }

    static async updateCandidateProfileSearch(search: JobCandidateSearchForm) {
        await Http.patch(`/v3/hr/candidates/me/search`, {
            ...search,
            locations: search?.locations?.map?.((location) => JSON.stringify(location))
        })
    }

    static async createJob(job: EditJob): Promise<ResponseV3<{ id: string }>> {
        const response = await Http.post(`/v3/hr/jobs`, job)

        return response.data
    }

    static async getQualifiedCandidates(id: string): Promise<ResponseV3<SelectedCandidateType[]>> {
        const response = await Http.get(`/v3/hr/jobs/${id}/qualified-candidates`)

        return response.data
    }

    static async getJob(jobId: string, format: 'raw' | 'default' = 'raw'): Promise<ResponseV3<GetJob>> {
        const response = await Http.get(`/v3/hr/jobs/${jobId}`, {
            params: {
                format
            }
        })

        return response.data
    }

    static async editJob(jobId: string, job: Partial<EditJob>): Promise<ResponseV3<{ id: string }>> {
        const response = await Http.patch(`/v3/hr/jobs/${jobId}`, job)

        return response.data
    }

    static async getCandidateListForJob(
        jobId: string,
        candidateStatus: CandidateStatus
    ): Promise<ResponseV3<Candidate[], undefined>> {
        const response = await Http.get(`/v3/hr/jobs/${jobId}/candidates`, {
            params: {
                status: candidateStatus
            }
        })

        return response.data
    }

    static async getJobCandidate(jobId: string, candidateId: string): Promise<ResponseV3<Candidate, undefined>> {
        const response = await Http.get(`/v3/hr/jobs/${jobId}/candidates/${candidateId}`)

        return response.data
    }

    static async declareInterest(jobId: string, candidateId: string): Promise<void> {
        await Http.post(`/v3/hr/candidates/${candidateId}/interest/${jobId}`)
    }

    static async declineCandidate(jobId: string, candidateId: string): Promise<void> {
        await Http.post(`/v3/hr/candidates/${candidateId}/decline/${jobId}`)
    }

    static async declareHiring(jobId: string, candidateId: string, hired: boolean): Promise<void> {
        await Http.put(`/v3/hr/candidates/${candidateId}/hiring/${jobId}`, {
            hired
        })
    }

    static async searchPublicJobs(search: JobCandidateSearchForm): Promise<ResponseV3<Job[], MetaPager>> {
        const result = await Http.post(`/v3/hr/jobs/search`, {
            ...search,
            locations: search?.locations?.map?.((location) => JSON.stringify(location))
        })

        return result.data
    }

    static async getMyLastViews(): Promise<ResponseV3<JobLastViews>> {
        const response = await Http.get(`/v3/public-homepage/jobs`)

        return response.data
    }

    static async getMyLastOffer(): Promise<ResponseV3<Job[]>> {
        const response = await Http.get(`/v3/public-homepage/my-jobs`)

        return response.data
    }
}

export default JobsApi
