<script setup lang="ts">
    import { AboutRouteName, ClubRouteName, ProRouteName } from '@/vars/RouteName'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import { useAuthStore } from '@/libs/stores/auth.store'
    import { BusinessLineName } from '@/vars/AuthAttr'
    import { useI18n } from 'vue-i18n'

    type FooterLinks = {
        title: string
        links: {
            label: string
            to?: { name: string }
            href?: string
        }[]
    }
    const { hasBusinessLine } = useAuthStore()
    const { t } = useI18n()

    const spaces: FooterLinks = {
        title: t('spaces.title'),
        links: [
            {
                label: t('spaces.club'),
                to: { name: hasBusinessLine(BusinessLineName.LECLUB) ? ClubRouteName.HOME : AboutRouteName.WEWEB_CLUB }
            },
            {
                label: t('spaces.bs'),
                to: {
                    name: hasBusinessLine(BusinessLineName.BUSINESS)
                        ? ProRouteName.HOME
                        : AboutRouteName.WEWEB_BUSINESS_SOLUTIONS
                }
            }
        ]
    }

    const FF: FooterLinks = {
        title: 'Frenchfounders',
        links: [
            {
                label: t('about'),
                to: { name: AboutRouteName.WEWEB_ABOUT }
            },
            {
                label: t('recrute'),
                href: 'https://www.welcometothejungle.com/fr/companies/frenchfounders/jobs'
            },
            {
                label: t('legal'),
                to: { name: AboutRouteName.WEWEB_LEGAL_MENTIONS }
            },
            {
                label: t('cgu'),
                to: { name: AboutRouteName.WEWEB_CGV }
            },
            {
                label: t('cgu_entreprise'),
                to: { name: AboutRouteName.WEWEB_CGV_ENTREPRISE }
            },
            {
                label: t('confidential'),
                to: { name: AboutRouteName.WEWEB_PRIVACY }
            }
        ]
    }
    const socials: FooterLinks = {
        title: t('social'),
        links: [
            {
                label: 'LinkedIn',
                href: 'https://www.linkedin.com/company/frenchfounders'
            },
            {
                label: 'Instagram',
                href: 'https://www.instagram.com/frenchfounders_inc/'
            },
            {
                label: 'X',
                href: 'https://twitter.com/frenchfounders'
            },
            {
                label: 'Facebook',
                href: 'https://www.facebook.com/Frenchfounders'
            }
        ]
    }
</script>

<template>
    <div class="footer-links">
        <div class="footer-links__spaces footer-links__column">
            <div class="footer-links__title mb-2">{{ spaces.title }}</div>
            <div v-for="link in spaces.links" :key="link.label" class="mt-2">
                <SpaRouterLink v-if="link.to" :to="link.to">{{ link.label }}</SpaRouterLink>
                <a v-else-if="link.href" :href="link.href">{{ link.label }}</a>
            </div>
        </div>
        <div class="footer-links__frenchfounders footer-links__column">
            <div class="footer-links__title mb-2">{{ FF.title }}</div>
            <div v-for="link in FF.links" :key="link.label" class="mt-2">
                <SpaRouterLink v-if="link.to" :to="link.to">{{ link.label }}</SpaRouterLink>
                <a v-else-if="link.href" :href="link.href">{{ link.label }}</a>
            </div>
        </div>
        <div class="footer-links__socials footer-links__column">
            <div class="footer-links__title mb-2">{{ socials.title }}</div>
            <div v-for="link in socials.links" :key="link.label" class="mt-2">
                <SpaRouterLink v-if="link.to" :to="link.to">{{ link.label }}</SpaRouterLink>
                <a v-else-if="link.href" :href="link.href">{{ link.label }}</a>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';
    .footer-links {
        display: grid;
        gap: var(--scale-4);
        grid-template-areas:
            'spaces FF'
            '. FF'
            'socials FF';

        @media screen and (min-width: $breakpoints-lg) {
            display: flex;
            flex-flow: row wrap;
            gap: var(--scale-10);
        }

        &__spaces {
            grid-area: spaces;
        }

        &__frenchfounders {
            grid-area: FF;
            padding-left: var(--scale-4);
            @media screen and (min-width: $breakpoints-md) {
                width: 12.5em;
                padding-left: 0;
            }
        }

        &__socials {
            grid-area: socials;
        }

        &__column {
            width: initial;
            @media screen and (min-width: $breakpoints-md) {
                width: 12.5em;
            }
        }

        &__title {
            font-weight: bold;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "spaces": {
            "title": "Espace privé",
            "club": "Club privé",
            "bs": "Espace entreprise"
        },
        "about": "À propos",
        "recrute": "Recrutement",
        "legal": "Mentions légales",
        "cgu": "CGU",
        "cgu_entreprise": "CGU entreprises",
        "confidential": "Politique de confidentialité",
        "social": "Social"
    },
    "en": {
        "spaces": {
            "title": "Private spaces",
            "club": "Private club",
            "bs": "Business Solutions"
        },
        "about": "About",
        "recrute": "Recruitment",
        "legal": "Legal Notice",
        "cgu": "Terms",
        "cgu_entreprise": "Companies terms",
        "confidential": "Privacy Policy",
        "social": "Social"
    }
}
</i18n>
