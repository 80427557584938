import { ResponseV3 } from '@/types/API.type'
import { Configuration, ConfigurationRelationshipEstablishments } from '@/types/Configuration.type'
import { Header } from '@/types/Header.type'
import { Partner } from '@/types/Partner.type'
import { StakeItem } from '@/types/response/Stakes'
import Http from './ApiClient'
import { ProductUpdatesResponse } from '@/types/response/configuration-api/ProductUpdate.type'

class ConfigurationApi {
    static async getLeClubHeaderConfiguration(): Promise<ResponseV3<Header, undefined>> {
        const response = await Http.get('v3/config/header')

        return response.data
    }

    static async getLeClubNotifications() {
        const response = await Http.get('notifications')

        return response.data
    }

    static async getPartnerLast(): Promise<ResponseV3<Partner[]>> {
        const response = await Http.get(`/v3/partners/last`)

        return response.data
    }

    static async getConfiguration(): Promise<Configuration> {
        const response = await Http.get('/configuration')
        return response.data
    }

    static async getConfigurationRelationshipEstablishments(): Promise<
        ResponseV3<ConfigurationRelationshipEstablishments>
    > {
        const response = await Http.get('/v3/relationship-establishments/configuration')

        return response.data
    }

    static async contactFrenchFounders(subject: string, message: string): Promise<void> {
        const response = await Http.post(`/v3/contact`, {
            subject,
            message
        })

        if (response.status > 300) {
            throw response
        }

        return response.data
    }

    static async getStakes(): Promise<ResponseV3<StakeItem[]>> {
        const response = await Http.get('/v3/config/stakes')
        return response.data
    }
    static async getLastProductUpdates(): Promise<ResponseV3<ProductUpdatesResponse>> {
        const response = await Http.get('/v3/notion/whatsnew')

        return response.data
    }

    static async participateToBetaTesting() {
        const response = await Http.post('/v3/notion/beta-tester')

        return response.data
    }
}

export default ConfigurationApi
