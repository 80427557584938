<script setup lang="ts">
    import { computed } from 'vue'

    const props = defineProps<{
        count?: number
        maxCount?: number
        animated?: boolean
    }>()

    const countShown = computed(() => {
        if (!props.count) {
            return undefined
        }

        if (!props.maxCount || props.count <= props.maxCount) {
            return props.count
        }

        return `${props.maxCount}+`
    })
</script>

<template>
    <span class="counter" :class="{ 'counter--empty': !countShown, 'counter--animated': animated }">
        {{ countShown }}
    </span>
</template>

<style scoped lang="scss">
    .counter {
        --size: 1.5em;

        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0.75em 0.5em; // TODO: En dur car on a pas de scale pour .75em et car les '--scale-x' sont en "rem" actuellement
        font-size: 0.75em;
        height: var(--size);
        background: var(--theme-color-ace);
        border-radius: 99em;
        color: var(--theme-primary-text-color);
        font-weight: 600;
        vertical-align: top;

        &--empty {
            height: var(--scale-1);
            padding: var(--scale-1);
            vertical-align: top;
        }

        &--animated {
            animation-name: pulse;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
            animation-duration: 1.5s;
        }

        @keyframes pulse {
            0% {
                opacity: 1;
            }

            50% {
                opacity: 0.1;
            }

            100% {
                opacity: 1;
            }
        }
    }
</style>

<i18n lang="json"></i18n>
