<script setup lang="ts">
    import LogoMedia from '@/components/ui/medias/LogoMedia.vue'
    import { useI18n } from 'vue-i18n'
    import BaseLink from '@/components/ui/actions/BaseLink.vue'
    import { AboutRouteName } from '@/vars/RouteName'
    import BaseModal from '@/components/ui/basics/BaseModal.v2.vue'
    import { ref } from 'vue'
    import { useAuthStore } from '@/libs/stores/auth.store'
    import LottieView from '@/components/ui/wrappers/LottieView.vue'
    import BaseButton from '@/components/ui/actions/BaseButton.v2.vue'

    const { t } = useI18n()
    const authStore = useAuthStore()
    const modalTermOfUse = ref(false)

    const showTermOfUse = () => {
        if (!authStore.isLoggedIn) {
            modalTermOfUse.value = true
        }
    }
</script>

<template>
    <div class="apply-header">
        <div class="apply-header__tagline">
            <LogoMedia />
            <div class="apply-header__tagline-separator" />
            <div class="title-2 mt-2 text-center md:mt-0 md:text-left">
                <div>30 jours d'essai + membership Club</div>
                <div>à 1290$US au lieu de 1490$US</div>
            </div>
        </div>
        <div class="apply-header__content">
            <div>
                <div class="title-2" data-testId="title-ope-ten-years">{{ t('title') }}</div>
                <div class="text-4">
                    <div>1. Je dépose ma candidature au tarif préférentiel de 1290 $US au lieu de 1490 $US / an</div>
                    <div>2. Ma candidature est validée : je profite de mes 30 jours "satisfait ou remboursé"</div>
                    <div>3. Satisfait ? Mon membership se poursuit automatiquement</div>
                </div>

                <div class="mt-4">
                    <BaseLink reversed href="#term_of_use" @click="showTermOfUse">
                        {{ t('conditions') }}
                    </BaseLink>
                    <BaseLink reversed class="mt-2" :to="{ name: AboutRouteName.WEWEB_CLUB }" target="_blank">
                        {{ t('know_more') }}
                    </BaseLink>
                </div>
            </div>
            <div class="hidden md:block">
                <LottieView
                    path="/lottie/logo_ten_years.json"
                    background="transparent"
                    speed="1"
                    class="h-[300px] w-[300px]"
                    direction="1"
                    mode="normal"
                    autoplay
                />
            </div>
        </div>
        <div class="mt-6 flex justify-center pb-4">
            <BaseButton href="#apply-blocks">Je profite de l'offre</BaseButton>
        </div>

        <BaseModal v-model="modalTermOfUse">
            <template #default>
                <div v-safe-html="t('summary_explanation_batch_promotion')" />
            </template>
        </BaseModal>
    </div>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';
    .apply-header {
        @apply bg-legacy text-nuage;
        padding: var(--scale-6);

        @media screen and (min-width: $breakpoints-md) {
            padding: var(--scale-20);
            padding-top: var(--scale-8);
            padding-bottom: 0;
        }

        &__tagline {
            @media screen and (min-width: $breakpoints-md) {
                @apply flex justify-center;
                gap: var(--scale-6);
            }
            text-align: center;
        }
        &__tagline-separator {
            @apply min-h-max bg-nuage;
            min-width: var(--scale-px);
        }

        &__content {
            margin-top: var(--scale-6);
            @media screen and (min-width: $breakpoints-md) {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "tagline": "Le réseau business <br />francophone international",
        "title": "Offre limitée !",
        "conditions": "Voir les conditions d’utilisation",
        "know_more": "En savoir plus sur le Club",
        "summary_explanation_batch_promotion": "<strong>Conditions d'utilisation :</strong><br/>Si votre candidature est validée, votre paiement sera processé. Durant les 30 jours d'essai, vous pouvez décider à tout moment d’arrêter votre membership et de vous faire rembourser intégralement, sans frais. Pour cela, merci d'adresser votre demande à membership{'@'}frenchfounders.com, en mettant en copie le Relationship Manager qui sera votre contact privilégié dans le Club.<br/>Passé ce délai, vous continuerez à bénéficier de tous les avantages associés à votre membership Proximity, pour une durée d’un an, et ce sans action nécessaire de votre part."
    }
}
</i18n>
