<script setup lang="ts">
    import { computed } from 'vue'
    // import { getNavigationItems as getClubMainNavigationItems } from '@/libs/navigations/leclub/mainNavigation'
    import { useProfileNavigation as getClubProfileNavigationItems } from '@/libs/navigations/leclub/profileNavigation'

    import { useQuery } from '@tanstack/vue-query'
    import { useI18n } from 'vue-i18n'
    import { QueryName } from '@/vars/QueryAttr'
    import { useAuthStore } from '@/libs/stores/auth.store'
    import { HeaderMenuLink } from '@/types/Header.type'
    import ConfigurationApi from '@/libs/api/Configuration.api'
    import { RoleName } from '@/vars/AuthAttr'
    import { useRouter } from 'vue-router'
    import { ClubRouteName } from '@/vars/RouteName'
    import { useNavigationResolver } from '@/libs/compositions/useNavigationResolver'
    import ResourceApi from '@/libs/api/Resource.api'
    import { ArticlePublishersName } from '@/vars/ResourceAttr'

    const store = useAuthStore()
    const { t } = useI18n()
    const router = useRouter()
    const { redirectBounding } = useNavigationResolver()

    const { data } = useQuery({
        queryKey: [QueryName.HEADER_CONFIGURATION],
        queryFn: ConfigurationApi.getLeClubHeaderConfiguration,
        enabled: () => store.isLoggedIn
    })

    const { data: videos } = useQuery({
        queryKey: [QueryName.RESOURCES_VIDEO_LIST, 'videos'],
        queryFn: () =>
            ResourceApi.getVideoList({
                publisher: ArticlePublishersName.CLUB,
                limit: 3
            })
    })

    const { data: podcasts } = useQuery({
        queryKey: [QueryName.RESOURCES_PODCAST_LIST, 'podcast'],
        queryFn: () =>
            ResourceApi.getPodcastList({
                publisher: ArticlePublishersName.CLUB,
                limit: 3
            })
    })

    const newCompanies = computed(() => (data.value && data.value.data && data.value.data.partners) || [])

    const clubs = computed(
        () =>
            (data.value &&
                data.value.data &&
                data.value.data.clubs &&
                data.value.data.clubs
                    .filter((club) => !['investor'].includes(club.slug))
                    .map((club) => ({
                        to: { name: ClubRouteName.CLUB_SINGLE, params: { club: club.slug } },
                        label: club.name
                    }))) ||
            []
    )

    const myHub = computed(() => {
        return data.value?.data?.hubs?.find((hub) => hub.id === store.authentifiedUser?.business_center?.id) || null
    })
    const hubs = computed(() => {
        const regions = ['EMEA', 'APAC', 'AMERICAS']

        return regions.map((region) => ({
            name: region,
            children:
                (data.value &&
                    data.value.data &&
                    data.value.data.hubs &&
                    data.value.data.hubs
                        .filter((hub) => hub.region.toLowerCase() === region.toLowerCase())
                        .map((hub) => {
                            const { href, to } = redirectBounding({
                                name: ClubRouteName.HUB_SINGLE,
                                params: { hub: hub.slug }
                            })
                            return {
                                href: href || (to && router.resolve(to).href),
                                label: hub.name.replace('Aside du Sud-Est', 'Asie SE')
                            }
                        })) ||
                []
        }))
    })

    const cityLinks = computed(() => {
        const cities: HeaderMenuLink[] = []
        if (store.authentifiedUser) {
            const city =
                store.authentifiedUser.city || store.authentifiedUser.location?.geoloc.location?.formatted_address

            if (city) {
                cities.push({
                    to: { name: ClubRouteName.EVENTS_LISTING, query: { city: city } },
                    label: city,
                    icon: 'marker'
                })
            }
        }

        const staticCities = [
            {
                to: { name: ClubRouteName.EVENTS_LISTING, query: { city: 'New York' } },
                label: 'New York',
                icon: 'new-york'
            },
            {
                to: { name: ClubRouteName.EVENTS_LISTING, query: { city: 'Paris' } },
                label: 'Paris',
                icon: 'paris'
            },
            {
                to: { name: ClubRouteName.EVENTS_LISTING, query: { city: 'Londres' } },
                label: 'Londres',
                icon: 'london'
            },
            {
                to: { name: ClubRouteName.EVENTS_LISTING, query: { city: 'Madrid' } },
                label: 'Madrid',
                icon: 'madrid'
            },
            {
                to: { name: ClubRouteName.EVENTS_LISTING, query: { city: 'Hong Kong' } },
                label: 'Hong Kong',
                icon: 'hong-kong'
            }
        ]

        staticCities.forEach((c: HeaderMenuLink) => {
            cities.push(c)
        })

        return cities
    })

    const replays = computed(
        () =>
            (data.value &&
                data.value.data &&
                data.value.data.replays &&
                data.value.data.replays.map((replay) => ({
                    to: { name: ClubRouteName.EVENT_REPLAY, params: { id: replay.id, eventSlug: replay.slug } },
                    label: replay.title
                }))) ||
            []
    )

    const articles = computed(
        () =>
            (data.value &&
                data.value.data &&
                data.value.data.articles &&
                data.value.data.articles.map((article) => ({
                    to: { name: ClubRouteName.ARTICLE_SINGLE, params: { id: article.slug } },
                    label: article.title
                }))) ||
            []
    )

    const videosFormated = computed(() => {
        if (!videos.value) {
            return []
        }

        return videos.value.map((video) => ({
            to: { name: ClubRouteName.VIDEO_SINGLE, params: { slug: video.slug } },
            label: video.title
        }))
    })

    const podcastsFormated = computed(() => {
        if (!podcasts.value) {
            return []
        }

        return podcasts.value.map((podcast) => ({
            to: { name: ClubRouteName.PODCAST_SINGLE, params: { slug: podcast.slug } },
            label: podcast.title
        }))
    })

    const navigationItems = computed(() => [
        {
            title: t('community.title'),
            items: [
                {
                    key: 'members',
                    title: t('community.members.title'),
                    subtitle: t('community.members.subtitle'),
                    icon: 'groups',
                    links: [
                        {
                            to: { name: ClubRouteName.MEMBER_LIST_CONTACTS },
                            label: t('community.members.contacts')
                        },
                        {
                            to: { name: ClubRouteName.MEMBER_LIST_FAVORITES },
                            label: t('community.members.favorites')
                        },
                        {
                            to: { name: ClubRouteName.MEMBER_LIST_SUGGESTIONS },
                            label: t('community.members.suggestions')
                        }
                    ],
                    footerLinks: [
                        {
                            to: { name: ClubRouteName.MEMBER_LIST_ALL },
                            label: t('community.members.all')
                        }
                    ]
                },
                {
                    key: 'companies',
                    title: t('community.companies.title'),
                    subtitle: t('community.companies.subtitle'),
                    icon: 'home_work',
                    seeCompanies: t('community.companies.seeCompanies'),
                    newsCompanies: t('community.companies.newsCompanies'),
                    companies: newCompanies.value
                },
                {
                    key: 'clubs',
                    title: t('community.clubs.title'),
                    subtitle: t('community.clubs.subtitle'),
                    icon: 'crowdsource',
                    links: clubs.value
                },
                {
                    key: 'hubs',
                    title: t('community.hubs.title'),
                    subtitle: t('community.hubs.subtitle'),
                    categories: hubs.value,
                    allHubs: t('community.hubs.allHubs'),
                    myHubValue: myHub.value,
                    myHub: t('community.hubs.myHub'),
                    icon: 'public'
                }
            ]
        },
        {
            title: t('events.title'),
            items: [
                {
                    key: 'meetings',
                    title: t('events.meetings.title'),
                    subtitle: t('events.meetings.subtitle'),
                    icon: 'celebration',
                    links: [
                        {
                            to: { name: ClubRouteName.EVENTS_LISTING, query: { type: 'physical' } },
                            label: t('events.meetings.physical')
                        },
                        {
                            to: { name: ClubRouteName.EVENTS_LISTING, query: { type: 'live' } },
                            label: t('events.meetings.live')
                        }
                    ],
                    footerLinks: [
                        {
                            to: { name: ClubRouteName.EVENTS_LISTING },
                            label: t('events.meetings.all')
                        }
                    ]
                },
                {
                    key: 'cities',
                    title: t('events.cities.title'),
                    subtitle: t('events.cities.subtitle'),
                    icon: 'location_city',
                    links: cityLinks.value,
                    footerLinks: [
                        {
                            to: { name: ClubRouteName.EVENTS_LISTING },
                            label: t('events.cities.all')
                        }
                    ]
                }
            ]
        },
        {
            title: t('resources.title'),
            items: [
                {
                    even: true,
                    key: 'replays',
                    title: t('resources.replays.title'),
                    subtitle: t('resources.replays.subtitle'),
                    icon: 'hangout_video',
                    links: replays.value,
                    footerLinks: [
                        {
                            to: { name: ClubRouteName.EVENTS_LIVE_AND_REPLAY },
                            label: t('resources.replays.all')
                        }
                    ]
                },
                {
                    even: true,
                    key: 'articles',
                    title: t('resources.articles.title'),
                    subtitle: t('resources.articles.subtitle'),
                    icon: 'book_2',
                    links: articles.value,
                    footerLinks: [
                        {
                            to: { name: ClubRouteName.ARTICLE_LIST },
                            label: t('resources.articles.all')
                        }
                    ]
                },
                {
                    key: 'videos',
                    title: t('resources.videos.title'),
                    subtitle: t('resources.videos.subtitle'),
                    icon: 'play_circle',
                    links: videosFormated.value,
                    footerLinks: [
                        {
                            to: { name: ClubRouteName.VIDEO_LIST },
                            label: t('resources.videos.all')
                        }
                    ]
                },
                {
                    key: 'podcasts',
                    title: t('resources.podcasts.title'),
                    subtitle: t('resources.podcasts.subtitle'),
                    icon: 'micro',
                    links: podcastsFormated.value,
                    footerLinks: [
                        {
                            to: { name: ClubRouteName.PODCAST_LIST },
                            label: t('resources.podcasts.all')
                        }
                    ]
                }
            ]
        },
        {
            title: t('investment.title'),
            items: [
                {
                    key: 'lefonds',
                    title: t('investment.lefonds.title'),
                    subtitle: t('investment.lefonds.subtitle'),
                    icon: 'wallet',
                    links: [
                        {
                            href: `https://www.lefonds.vc/`,
                            label: t('investment.lefonds.more')
                        }
                    ]
                },
                {
                    key: 'investors_club',
                    title: t('investment.investors_club.title'),
                    subtitle: t('investment.investors_club.subtitle'),
                    icon: 'toll',
                    links:
                        store.hasRole(RoleName.INVESTORSCLUB) || store.hasRole(RoleName.ADMIN)
                            ? [
                                  {
                                      to: { name: ClubRouteName.CLUB_SINGLE_PROJECT, params: { club: 'investor' } },
                                      label: t('investment.investors_club.deals')
                                  },
                                  {
                                      to: { name: ClubRouteName.CLUB_SINGLE_EVENTS, params: { club: 'investor' } },
                                      label: t('investment.investors_club.events')
                                  },
                                  {
                                      to: { name: ClubRouteName.CLUB_SINGLE_VIDEOS, params: { club: 'investor' } },
                                      label: t('investment.investors_club.videos')
                                  }
                              ]
                            : [
                                  {
                                      to: { name: ClubRouteName.CLUB_SINGLE_JOIN, params: { club: 'investor' } },
                                      label: t('investment.investors_club.more')
                                  }
                              ],
                    footerLinks: [
                        (store.hasRole(RoleName.INVESTORSCLUB) || store.hasRole(RoleName.ADMIN)) && {
                            to: { name: ClubRouteName.CLUB_SINGLE, params: { club: 'investor' } },
                            label: t('investment.investors_club.see')
                        }
                    ]
                }
            ].filter(Boolean)
        },
        {
            title: t('more.title'),
            items: [
                {
                    key: 'workspaces',
                    title: t('more.workspaces.title'),
                    subtitle: t('more.workspaces.subtitle'),
                    icon: 'scene',
                    links: [
                        {
                            to: { name: ClubRouteName.WORKSPACES, query: { location: 'New York' } },
                            label: 'New York'
                        },
                        {
                            to: { name: ClubRouteName.WORKSPACES, query: { location: 'Londres' } },
                            label: 'Londres'
                        },
                        {
                            to: { name: ClubRouteName.WORKSPACES, query: { location: 'Madrid' } },
                            label: 'Madrid'
                        },
                        {
                            to: { name: ClubRouteName.WORKSPACES, query: { location: 'The Great Room' } },
                            label: 'Hong Kong'
                        }
                    ],
                    footerLinks: [
                        {
                            to: { name: ClubRouteName.WORKSPACES },
                            label: t('more.workspaces.all')
                        }
                    ]
                },
                {
                    key: 'benefits',
                    title: t('more.benefits.title'),
                    subtitle: t('more.benefits.subtitle'),
                    icon: 'award_star',
                    links: [
                        {
                            to: { name: ClubRouteName.BENEFITS, params: { category: 'business' } },
                            label: t('more.benefits.business')
                        },
                        {
                            to: { name: ClubRouteName.BENEFITS, params: { category: 'hotel' } },
                            label: t('more.benefits.hotel')
                        },
                        {
                            to: { name: ClubRouteName.BENEFITS, params: { category: 'travel' } },
                            label: t('more.benefits.travel')
                        },
                        {
                            to: { name: ClubRouteName.BENEFITS, params: { category: 'lifestyle' } },
                            label: t('more.benefits.lifestyle')
                        }
                    ],
                    footerLinks: [
                        {
                            to: { name: ClubRouteName.BENEFITS },
                            label: t('more.benefits.all')
                        }
                    ]
                },
                {
                    key: 'trips',
                    title: t('more.trips.title'),
                    subtitle: t('more.trips.subtitle'),
                    icon: 'flightsmode',
                    links: [
                        {
                            to: { name: ClubRouteName.TRIP_PLANNER_ADD },
                            label: t('more.trips.plan')
                        },
                        {
                            to: { name: ClubRouteName.TRIP_PLANNER_LIST },
                            label: t('more.trips.all')
                        }
                    ]
                }
            ]
        }
    ])

    // const navigationItems = computed(() => {
    //     return getClubMainNavigationItems()
    // })

    const { items } = getClubProfileNavigationItems()
</script>

<template>
    <slot v-bind="{ navigationItems, profileNavigation: items }"></slot>
</template>

<i18n lang="json">
{
    "fr": {
        "community": {
            "title": "Réseau",
            "members": {
                "title": "Les membres",
                "subtitle": "Pour explorer le réseau Frenchfounders",
                "contacts": "Mes contacts",
                "favorites": "Mes favoris",
                "suggestions": "Mes suggestions en attente",
                "all": "Voir tous les membres"
            },
            "companies": {
                "title": "Les entreprises",
                "subtitle": "Pour découvrir les entreprises et experts à votre service",
                "seeCompanies": "Voir les entreprises",
                "newsCompanies": "Ils viennent de nous rejoindre : "
            },
            "clubs": {
                "title": "Les insiders clubs",
                "subtitle": "Pour des échanges par verticales"
            },
            "hubs": {
                "title": "Les hubs",
                "subtitle": "Pour les échanges par zones géographiques",
                "allHubs": "Tous les hubs",
                "myHub": "Mon hub"
            }
        },
        "events": {
            "title": "Événements",
            "meetings": {
                "title": "Les rendez-vous",
                "subtitle": "Rencontrons-nous",
                "physical": "Les événements en physique",
                "live": "Les événements en ligne",
                "all": "Voir tous les événements"
            },
            "cities": {
                "title": "Recherche par ville",
                "subtitle": "Partout dans le monde",
                "all": "Voir toutes les villes"
            }
        },
        "resources": {
            "title": "Ressources",
            "replays": {
                "title": "Les replays d'événements",
                "subtitle": "",
                "all": "Voir tous les replays"
            },
            "articles": {
                "title": "Les articles",
                "subtitle": "",
                "all": "Voir tous les articles"
            },
            "videos": {
                "title": "Les contenus vidéo",
                "subtitle": "",
                "all": "Voir toutes les vidéos"
            },
            "podcasts": {
                "title": "Les podcasts",
                "subtitle": "",
                "all": "Voir tous les podcasts"
            }
        },
        "more": {
            "title": "Les + du Club",
            "workspaces": {
                "title": "Les espaces de travail",
                "subtitle": "Un bureau pour vos déplacements",
                "all": "Voir tous les espaces de travail"
            },
            "benefits": {
                "title": "Les avantages",
                "subtitle": "Proposés par nos partenaires et nos membres",
                "business": "Business",
                "hotel": "Hotel",
                "travel": "Travel",
                "lifestyle": "Lifestyle",
                "all": "Voir tous les avantages"
            },
            "trips": {
                "title": "Le trip planner",
                "subtitle": "Pour bénéficier du Club sur place",
                "plan": "Planifier un voyage",
                "all": "Mes voyages"
            }
        },
        "investment": {
            "title": "Investissement",
            "lefonds": {
                "title": "Le fonds",
                "subtitle": "Pour les individus qui souhaitent investir dans le fonds Frenchfounders",
                "more": "En savoir plus"
            },
            "investors_club": {
                "title": "Le Club Investisseurs",
                "subtitle": "Pour les investisseurs en startups",
                "more": "En savoir plus",
                "members": "Membres",
                "deals": "Deals",
                "videos": "Vidéos",
                "events": "Événements",
                "see": "Voir le Club Investisseurs"
            }
        }
    }
}
</i18n>
