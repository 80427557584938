import { createI18n } from 'vue-i18n'
import SharedStorage from './libs/core/SharedStorage'
import fr from './vars/i18n/fr.json'
import en from './vars/i18n/en.json'
import { StorageKey } from './vars/StorageAttr'
import { Lang } from '@/types/Lang.type'

const fallbackLocale = Lang.FR
const locales = [Lang.FR, Lang.EN]

function checkLocaleValidity(locale?: Lang) {
    return locale && locales.includes(locale) ? locale : undefined
}

const findNavigatorLanguage = () => {
    switch (window.navigator.language.split('-')[0].toLowerCase()) {
        case Lang.FR:
            return Lang.FR
        case Lang.EN:
            return Lang.EN
        default:
            return Lang.FR
    }
}

export const defaultLocale = () => {
    const localeFromStorage = checkLocaleValidity(SharedStorage.get(StorageKey.LOCALE)?.toLowerCase())
    const browserLocale = checkLocaleValidity(findNavigatorLanguage())
    return localeFromStorage || browserLocale || fallbackLocale
}

const i18n = createI18n({
    legacy: false,
    // detectBrowserLanguage: false,
    locale: defaultLocale(),
    fallbackLocale,
    messages: {
        fr,
        en
    },
    ...(import.meta.env?.MODE === 'test' && {
        fallbackWarn: false,
        missingWarn: false
    })
})

export default i18n
