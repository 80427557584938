import i18n from '@/i18n'
import { SpaName } from '@/vars/SpaAttr'
import axios, { RawAxiosRequestHeaders } from 'axios'
import { checkTokenInterceptor } from '@/libs/axios/interceptors/checkTokenInterceptor'
import { checkSegmentAnonymousIdInterceptor } from '@/libs/axios/interceptors/checkSegmentAnonymousIdInterceptor'
import { renewTokenInterceptor } from '@/libs/axios/interceptors/RenewTokenInterceptor'
import { handleAcceptLanguageInterceptor } from '@/libs/axios/interceptors/handleAcceptLanguageInterceptor'
import { handleMaintenanceInterceptor } from '@/libs/axios/interceptors/handleMaintenanceInterceptor'
import { handlePendingDeploymentInterceptor } from '@/libs/axios/interceptors/handlePendingDeploymentInterceptor'
import addCurrentRouteHeader from '../axios/interceptors/addCurrentRouteHeaderInterceptor'
import corsPreventionWithReload from '../axios/interceptors/corsPreventionWithReload'
import renewTokenFromHeader from '../axios/interceptors/renewTokenFromHeader'

/**
 * @debt architecture "Revoir l'architecture car on a des domaines avec des dossiers (ex: companies) et le reste dans des fichiers à la racine. À voir si on réorganiser correctement."
 */

const BASE_URL = import.meta.env.VITE_API_BASE_URL

const getContext = () => {
    switch (import.meta.env.VITE_SPA_RUNNING) {
        case SpaName.LENETWORK:
            return 'network'
        case SpaName.LECLUB:
            return 'club'
        case SpaName.PRO:
            return 'pro'
        default:
            return import.meta.env.VITE_SPA_RUNNING
    }
}

const getHeaders = () => {
    const headers: RawAxiosRequestHeaders = {
        'X-Accept-Version': '2.1',
        'X-Origin': 'web',
        'x-ff-platform': 'web',
        'x-ff-context': getContext(),
        'x-glados': import.meta.env.VITE_GLADOS_PASSWORD,
        'Accept-Language': i18n.global.locale.value
    }

    if (!headers['x-glados']) {
        delete headers['x-glados']
    }

    return headers
}

const Http = axios.create({
    headers: getHeaders(),
    baseURL: BASE_URL,
    withCredentials: true
})

if (import.meta.env.VITE_SPA_RUNNING !== SpaName.STORYBOOK) {
    Http.interceptors.request.use(checkTokenInterceptor)
    Http.interceptors.request.use(checkSegmentAnonymousIdInterceptor)
    Http.interceptors.request.use(addCurrentRouteHeader)

    Http.interceptors.response.use(undefined, (err) => {
        return renewTokenInterceptor(Http, err)
    })

    Http.interceptors.response.use(renewTokenFromHeader)
    Http.interceptors.response.use(undefined, handlePendingDeploymentInterceptor)
    // Désactiver pour le moment en attente d'un meilleur solution car sinon reload infinie sur la page

    Http.interceptors.response.use(undefined, corsPreventionWithReload)
    Http.interceptors.response.use(handleMaintenanceInterceptor, undefined)

    Http.interceptors.request.use(handleAcceptLanguageInterceptor)
}

export default Http
