<script setup lang="ts">
    import { computed, ref, watch } from 'vue'
    import { useI18n } from 'vue-i18n'
    import 'particles.js'
    import CardWrapper from '@/components/ui/wrappers/cards/CardWrapper.vue'
    import CardBody from '@/components/ui/wrappers/cards/CardBody.vue'
    import MaterialIcon from '@/components/ui/icons/MaterialIcon.vue'
    import { whatsNewParticlesConfig } from '@/vars/particles'
    import ModalWhatsNew from '@/components/partials/common/WhatsNew/ModalWhatsNew.vue'
    import { StorageKey } from '@/vars/StorageAttr'
    import { useQuery } from '@tanstack/vue-query'
    import { QueryName } from '@/vars/QueryAttr'
    import ConfigurationApi from '@/libs/api/Configuration.api'
    import { useSegment } from '@/libs/vendors/segment/segment'
    import { useRoute } from 'vue-router'

    const { t } = useI18n()
    const { analytics } = useSegment()
    const route = useRoute()

    const cardEl = ref<HTMLDivElement>()
    const particlesEl = ref<HTMLDivElement>()
    const isProductUpdateOpen = ref(false)
    const isFloatingCardVisible = ref(false)

    const PARTICLES_ELEMENT_ID = 'whats-new-particles'

    const { data: productUpdates } = useQuery({
        queryKey: [QueryName.PRODUCT_UPDATES],
        queryFn: () => ConfigurationApi.getLastProductUpdates()
    })

    const newProductUpdateList = computed(() => {
        const lastSeenProductDate = window.localStorage.getItem(StorageKey.PRODUCT_UPDATE_LAST_DATE)

        if (!productUpdates.value?.data) {
            return []
        }

        if (!lastSeenProductDate) {
            return productUpdates.value.data
        }

        return productUpdates.value.data.filter(
            (productUpdate) => new Date(productUpdate.date).getTime() > new Date(lastSeenProductDate).getTime()
        )
    })

    watch(
        newProductUpdateList,
        () => {
            if (newProductUpdateList.value?.length) {
                isFloatingCardVisible.value = true
            }
        },
        { immediate: true }
    )

    watch(particlesEl, () => {
        if (particlesEl.value) {
            window.particlesJS(PARTICLES_ELEMENT_ID, whatsNewParticlesConfig)
        }
    })

    const onShowProductUpdate = () => {
        isProductUpdateOpen.value = true
        isFloatingCardVisible.value = false

        analytics.track('community:whats_new')

        const particlesCanvas = document.getElementById(PARTICLES_ELEMENT_ID)?.querySelector('canvas')
        particlesCanvas?.remove()
        window.localStorage.setItem(StorageKey.PRODUCT_UPDATE_LAST_DATE, new Date().toISOString()) // On met à jour comme ceci pour éviter que `lastSeenProductDate` soit réactif
    }
</script>

<template>
    <div v-if="newProductUpdateList.length && !route.meta.hideWhatsNew" class="whats-new">
        <CardWrapper
            v-if="isFloatingCardVisible"
            id="whats-new"
            ref="cardEl"
            class="whats-new__card"
            data-testid="whats-new"
            @click="onShowProductUpdate"
        >
            <CardBody class="whats-new__card-body">
                <h6 class="inline-flex items-center gap-4 font-medium">
                    <span class="text-sm">{{ t('whats_new') }}</span>
                    <MaterialIcon class="title-4" name="arrow_forward" />
                </h6>
                <p class="text-xs text-gray-3">{{ t('whats_new_text') }}</p>
            </CardBody>
        </CardWrapper>
        <ModalWhatsNew v-model="isProductUpdateOpen" :product-updates="productUpdates?.data || []" />
        <div :id="PARTICLES_ELEMENT_ID" ref="particlesEl" class="whats-new__particles"></div>
    </div>
</template>

<style scoped lang="scss">
    .whats-new {
        position: fixed;
        bottom: var(--scale-8r);
        left: var(--scale-6r);
        z-index: 100;
        cursor: pointer;

        &__card {
            transition: all 0.2s ease-out;

            &:hover {
                scale: 1.02;
            }
        }

        &__card-body {
            padding: var(--scale-4r);
        }

        &__particles {
            $offset: calc(var(--scale-4) * -1);
            position: absolute;
            top: $offset;
            left: $offset;
            right: $offset;
            bottom: $offset;
            z-index: -1;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "whats_new": "Découvrez les nouveautés",
        "whats_new_text": "Voir la liste des changements"
    },
    "en": {
        "whats_new": "Discover the new features",
        "whats_new_text": "See the list of changes"
    }
}
</i18n>
