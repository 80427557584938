import { StartApplyInformationsForm } from '@/types/forms/apply/StartApplyInformationsForm.type'
import { GetApplyInformationsResponse } from '@/types/response/apply/GetApplyInformationsResponse.type'
import { ResponseV3 } from '@/types/response/ResponseV3.type'
import { QueryName } from '@/vars/QueryAttr'
import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from '@tanstack/vue-query'
import ApplyApi from '../api/Apply.api'
import { computed, toValue } from 'vue'
import { useAuthStore } from '@/libs/stores/auth.store'
import useBpiApply from '@/views/applies/leclub/partials/compositions/useBpiApply'
import useOpeTenYears from '@/views/applies/leclub/partials/compositions/useOpeTenYears'

export const queryKey = QueryName.APPLY_START_INFOS
export type QueryType = ResponseV3<GetApplyInformationsResponse>

export default function useApplyInformationsQuery(
    params?: StartApplyInformationsForm,
    opts?: Omit<
        UseQueryOptions<QueryType, AxiosError, QueryType, (QueryName | StartApplyInformationsForm | undefined)[]>,
        'queryFn' | 'queryKey'
    >
) {
    const authStore = useAuthStore()
    const { applyDefaultConfig, isApplyBpi } = useBpiApply()
    const { isApplyTenYears, applyDefaultConfig: applyDefaultConfigTenYears } = useOpeTenYears()
    const enabled = computed(() => authStore.isLoggedIn)

    if (isApplyBpi.value) {
        params = applyDefaultConfig.value
    }
    if (isApplyTenYears.value) {
        params = applyDefaultConfigTenYears.value
    }
    return useQuery<
        Awaited<ReturnType<typeof ApplyApi.getApplyInformations>>,
        AxiosError,
        Awaited<ReturnType<typeof ApplyApi.getApplyInformations>>
    >({
        queryKey: [QueryName.APPLY_START_INFOS, params],
        queryFn: () => ApplyApi.getApplyInformations(toValue(params)),
        staleTime: 10000,
        refetchOnMount: true,
        enabled,
        ...opts
    })
}
