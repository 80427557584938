<script setup lang="ts">
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import { computed, ref, watch } from 'vue'
    import { vElementHover } from '@vueuse/components'
    import { useI18n } from 'vue-i18n'

    const props = defineProps<{
        vote: number
        isEditable?: boolean
    }>()

    const emit = defineEmits<{
        select: [value: number]
    }>()

    const { t } = useI18n()
    const isHovered = ref(false)
    const valueShow = ref<number>(props.vote)

    watch(isHovered, () => {
        if (!isHovered.value) {
            valueShow.value = props.vote
        }
    })

    const fullStar = computed(() => {
        return Math.trunc(valueShow.value)
    })

    const halfStar = computed(() => {
        const roundValue = Math.round(valueShow.value)
        if (roundValue > valueShow.value) {
            return 1
        }
        return 0
    })

    const emptyStar = computed(() => {
        const roundValue = Math.round(valueShow.value)
        return 5 - roundValue
    })

    const starsToShow = computed(() => {
        const stars: { name: string }[] = []
        for (let i = 0; i < fullStar.value; i++) {
            stars.push({ name: 'star-full' })
        }
        for (let i = 0; i < halfStar.value; i++) {
            stars.push({ name: 'star-half' })
        }
        for (let i = 0; i < emptyStar.value; i++) {
            stars.push({ name: 'star-2' })
        }
        return stars
    })

    const onClickStar = (starNumber: number) => {
        if (props.isEditable) {
            emit('select', starNumber)
        }
    }

    const onHoverRate = (state: boolean) => {
        if (!props.isEditable) {
            return
        }
        isHovered.value = state
    }
    const onHover = (starNumber: number) => {
        if (!props.isEditable) {
            return
        }
        valueShow.value = starNumber
    }
</script>

<template>
    <div v-element-hover="onHoverRate" class="vote">
        <p>{{ t('bad') }}</p>
        <div class="vote__stars">
            <BaseIcon
                v-for="(value, index) in starsToShow"
                :key="index"
                v-element-hover="() => onHover(index + 1)"
                :name="value.name"
                legacy
                class="vote__star"
                :class="{
                    'vote__star--select': isEditable
                }"
                @click="() => onClickStar(index + 1)"
            />
        </div>
        <p>{{ t('excellent') }}</p>
    </div>
</template>

<style lang="scss" scoped>
    .vote {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        gap: var(--scale-1);

        &__stars {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &__star {
            font-size: 1.5em;
            color: var(--theme-text-color);

            &--select {
                cursor: pointer;
                font-size: 2.5em;
            }
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "excellent": "Excellente",
        "bad": "Mauvaise"
    },
    "en": {
        "excellent": "Excellent",
        "bad": "Bad"
    }
}
</i18n>
