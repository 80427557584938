<script setup lang="ts">
    import { ref } from 'vue'
    import ContainerTemplate from '@/components/templates/ContainerTemplate.vue'
    import LogoMedia from '@/components/ui/medias/LogoMedia.vue'
    import PublicMenu from '@/components/layouts/network/PublicLayout/partials/PublicMenu.vue'
    import usePublicHeaderNavigation, { MainNavigationItem } from '@/libs/compositions/usePublicHeaderNavigation'
    import HeaderProfileNavigation from '@/components/layouts/common/header/navigations/profile/HeaderProfileNavigation.vue'
    import { useProfileNetworkNavigation as useProfileNetworkNavigation } from '@/libs/navigations/network/profileNavigation'
    import HeaderNavigationTransverse from '@/components/layouts/common/header/navigations/HeaderNavigationTransverse.vue'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import { AboutRouteName } from '@/vars/RouteName'
    import { StorageKey } from '@/vars/StorageAttr'
    import { useRoute } from 'vue-router'
    import { watch } from 'vue'
    import SharedStorage from '@/libs/core/SharedStorage'

    const HEADER_HEIGHT = '6.25em'
    const { mainNavigation } = usePublicHeaderNavigation()

    const profileNavigation = useProfileNetworkNavigation()
    const route = useRoute()

    const showNavigationMenu = ref<MainNavigationItem | undefined>(undefined)

    const onClickLogo = () => {
        SharedStorage.delete(StorageKey.PERSONA_SELECTED)
    }

    watch(route, () => (showNavigationMenu.value = undefined))
</script>

<template>
    <ContainerTemplate tag="header" class="header" public @mouseleave="showNavigationMenu = undefined">
        <div>
            <HeaderNavigationTransverse @mouseenter="showNavigationMenu = undefined" />
            <div class="mt-4 flex items-stretch">
                <SpaRouterLink
                    class="header__logo-wrapper"
                    :to="{ name: AboutRouteName.WEWEB_ABOUT }"
                    @click="onClickLogo"
                >
                    <LogoMedia class="header__logo" dark ephemeral @mouseenter="showNavigationMenu = undefined" />
                </SpaRouterLink>
                <nav class="header__nav header__nav--main">
                    <ul class="header__menu">
                        <li
                            v-for="navigationEntry in mainNavigation"
                            :key="navigationEntry.key"
                            class="header__item"
                            @mouseover="showNavigationMenu = navigationEntry"
                        >
                            {{ navigationEntry.label }}
                        </li>
                    </ul>
                </nav>
                <div class="header__separator" @mouseenter="showNavigationMenu = undefined">
                    <!-- Séparateur utilisé simplement pour avoir une détection pour fermer le menu ouvert -->
                </div>

                <PublicMenu
                    :header-height="HEADER_HEIGHT"
                    :menu-to-open="showNavigationMenu"
                    @close="showNavigationMenu = undefined"
                />

                <HeaderProfileNavigation :profile="profileNavigation.items.value" />
            </div>
        </div>
    </ContainerTemplate>
</template>

<style scoped lang="scss">
    .header {
        position: relative;
        height: 6.25em;

        &__separator {
            flex: 1;
            height: 100%;
        }

        &__logo-wrapper {
            height: 2.5em;
            align-self: center;
            margin-right: 6.25em;
        }

        &__logo {
            height: 100%;
        }

        &__item {
            cursor: pointer;
            padding: var(--scale-1);
        }

        &__menu {
            display: flex;
            align-items: center;
            gap: var(--scale-8);
            font-size: 1.125em;
            font-weight: 500;
            list-style: none;

            &--universes {
                gap: var(--scale-4);
            }
        }

        &__menu-separator {
            color: var(--theme-color-gray--05);
        }

        &__nav {
            display: flex;

            &--main {
                margin-right: var(--scale-20);
                margin-left: 0;
            }
        }

        &__pill-icon {
            font-size: 1.75em;

            &--user {
                color: var(--theme-color-gray--03);
                font-size: 2em;
            }
        }

        &__pill-menu {
            display: flex;
            align-items: center;
            gap: var(--scale-1);
            padding: var(--scale-2) var(--scale-4);
            border: solid var(--scale-px) var(--theme-color-gray--05);
            border-radius: 99em;
            cursor: pointer;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {},
    "en": {}
}
</i18n>
