<script setup lang="ts">
    import BaseButton from '@/components/ui/basics/BaseButton.vue'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import BurgerIcon from '@/components/ui/icons/BurgerIcon.vue'
    import AvatarMedia from '@/components/ui/medias/AvatarMedia.vue'
    import AccordionNavigation from '@/components/ui/navigations/AccordionNavigation.vue'
    import BemTransition from '@/components/ui/transitions/BemTransition.vue'
    import { useAuthStore } from '@/libs/stores/auth.store'
    import { HeaderMenu } from '@/types/Header.type'
    import { RoleName } from '@/vars/AuthAttr'
    import { ButtonTheme } from '@/vars/ButtonAttr'
    import { RouteName } from '@/vars/RouteName'
    import { computed, ref } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { ClubRouteName } from '@/vars/RouteName'
    import { AvatarSize } from '@/vars/AvatarAttr'
    import HeaderMobileNavigationTransverse from '@/components/layouts/common/header/navigations/HeaderMobileNavigationTransverse.vue'
    import PlateformLogoNavigation from '@/components/ui/navigations/PlateformLogoNavigation.vue'

    const showMenu = defineModel<boolean>()
    const privateUrl = import.meta.env.VITE_PRIVATE_BASE_URL
    const { t } = useI18n()
    const store = useAuthStore()

    const isLogin = computed(() => {
        return store.isLoggedIn
    })

    const selectedMenu = ref<number[] | null>(null)
    const container = ref<HTMLElement | null>(null)
    const groupLocationShow = ref('')

    const props = defineProps<{
        navigationItems: HeaderMenu[]
        // profileNavigation?: HeaderMenu
    }>()

    function close() {
        showMenu.value = false
        selectedMenu.value = null
    }

    function selectMenu(category: number, item: number) {
        selectedMenu.value = [category, item]
        container.value?.scrollTo(0, 0)
    }

    const mobileNavigationItems = computed(() => {
        // break reference
        const newNavigation = JSON.parse(JSON.stringify(props.navigationItems))

        // replace summits to simple link
        const summitIndex = newNavigation[1].items.findIndex((i) => i.key === 'summits')
        if (summitIndex > 0) {
            newNavigation[1].items[summitIndex].href = 'https://www.frenchfounders.com/lessummits/'
        }

        // newNavigation.push(props.profileNavigation)

        return newNavigation
    })

    const selectedMenuItems = computed(() => {
        if (selectedMenu.value !== null) {
            const [category, item] = selectedMenu.value

            const selectedCategory = mobileNavigationItems.value[category]
            if (selectedCategory) {
                return selectedCategory.items && selectedCategory.items[item]
            }
        }

        return null
    })

    function openFolderLocation(name: string) {
        if (groupLocationShow.value === name) {
            groupLocationShow.value = ''
        } else {
            groupLocationShow.value = name
        }
    }
</script>

<template>
    <div class="menu__wrapper">
        <BaseButton :theme="ButtonTheme.DISCRET" class="menu__button" @click="showMenu = true">
            <BurgerIcon />
        </BaseButton>
        <div
            class="menu"
            :class="{
                'menu--open': showMenu
            }"
            @click="close"
        >
            <div class="menu__body" @click.stop>
                <div class="menu__header">
                    <PlateformLogoNavigation dark />

                    <button type="button" class="menu__close" @click="close">
                        <BaseIcon name="close" />
                    </button>
                </div>

                <div class="menu__profile">
                    <AvatarMedia
                        :src="store.authentifiedUser?.picture"
                        :size="AvatarSize.SMALL"
                        alt=""
                        class="menu__avatar"
                    />
                    <div v-if="store.authentifiedUser" class="menu__name">
                        {{ store.authentifiedUser.full_name }}
                    </div>
                    <div v-if="store.authentifiedUser" class="menu__position">
                        {{ store.authentifiedUser?.position }}
                        @{{ store.authentifiedUser?.company?.name }}
                    </div>
                    <a v-if="isLogin" :href="`${privateUrl}/member/me`" class="menu__link">
                        {{ t('profile') }}
                    </a>
                </div>
                <div
                    v-if="store.hasRole(RoleName.LECLUB_PARTNER_CONTACT) && !store.hasRole(RoleName.LECLUB)"
                    class="menu__list"
                >
                    <AccordionNavigation class="menu__item">
                        <template #header>
                            <span class="menu__item-name">
                                {{ t('account.title') }}
                            </span>
                        </template>
                        <template #default>
                            <div class="menu__item-list">
                                <RouterLink :to="{ name: RouteName.ACCOUNT_INFO }" class="menu__item-sub">
                                    <BaseIcon legacy name="arrow-right" class="menu__item-sub-icon" />
                                    <div class="menu__item-sub-details">
                                        <div class="menu__item-sub-name">
                                            {{ t('account.profile') }}
                                        </div>
                                    </div>
                                </RouterLink>
                                <a :href="`${privateUrl}/logout`" class="menu__item-sub">
                                    <BaseIcon legacy name="arrow-right" class="menu__item-sub-icon" />
                                    <div class="menu__item-sub-details">
                                        <div class="menu__item-sub-name">
                                            {{ t('account.logout') }}
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </template>
                    </AccordionNavigation>
                </div>
                <div v-else-if="store.hasRole(RoleName.LECLUB)" class="menu__list">
                    <AccordionNavigation
                        v-for="(category, categoryIndex) in mobileNavigationItems"
                        :key="category.title"
                        class="menu__item"
                    >
                        <template #header>
                            <span class="menu__item-name">
                                {{ category?.title }}
                            </span>
                        </template>
                        <template #default>
                            <!-- C'est ici le header mobile -->
                            <div class="menu__item-list">
                                <Component
                                    :is="item?.href ? 'a' : 'div'"
                                    v-for="(item, itemIndex) in category?.items"
                                    :key="item?.key"
                                    :href="item?.href"
                                    class="menu__item-sub"
                                    @click="item.href ? false : selectMenu(categoryIndex, itemIndex)"
                                >
                                    <BaseIcon legacy name="arrow-right" class="menu__item-sub-icon" />
                                    <!-- Ici c'est le gros  -->
                                    <div class="menu__item-sub-details">
                                        <div class="menu__item-sub-name">
                                            {{ item?.title }}
                                        </div>
                                        <div v-if="item.subtitle" class="menu__item-sub-description">
                                            {{ item.subtitle }}
                                        </div>
                                    </div>
                                </Component>
                            </div>
                        </template>
                    </AccordionNavigation>
                </div>
                <div class="menu__footer">
                    <div v-if="store.hasRole(RoleName.LECLUB)">
                        <a :to="{ name: ClubRouteName.MEMBER_RECOMMEND }" class="menu__item menu__item--footer">
                            <div class="menu__item-name">
                                <BaseIcon legacy name="gift" class="menu__item-icon" />
                                {{ t('sponsor.title') }}
                            </div>
                            <div class="menu__item-subname">
                                {{ t('sponsor.subtitle') }}
                            </div>
                        </a>
                    </div>
                    <HeaderMobileNavigationTransverse />
                </div>
                <BemTransition name="level">
                    <div v-if="selectedMenuItems" class="menu__level">
                        <div class="menu__previous" @click="selectedMenu = null">
                            <BaseIcon legacy name="arrow-left" class="menu__previous-icon" />
                            {{ t('back') }}
                        </div>
                        <div class="menu__item-sub-details menu__item-sub-details--level">
                            <div class="menu__item-sub-name">
                                {{ selectedMenuItems.title }}
                            </div>
                            <div class="menu__item-sub-description">
                                {{ selectedMenuItems.subtitle }}
                            </div>
                        </div>
                        <div v-if="selectedMenuItems.links" class="menu__level-body">
                            <template v-for="link in selectedMenuItems.links" :key="link.label">
                                <Component
                                    :is="link.to ? 'RouterLink' : 'a'"
                                    :to="link.to"
                                    :href="link.href"
                                    :data-important="link.label"
                                    class="menu__level-item menu__level-item--link"
                                >
                                    {{ link.label }}
                                </Component>
                            </template>
                            <template v-for="link in selectedMenuItems.footerLinks" :key="link.label">
                                <Component
                                    :is="link.to ? 'RouterLink' : 'a'"
                                    :to="link.to"
                                    :href="link.href"
                                    :data-important="link.label"
                                    class="menu__level-item menu__level-item--footer"
                                >
                                    <BaseIcon name="arrow-right" class="menu__item-sub-icon" />
                                    {{ link.label }}
                                </Component>
                            </template>

                            <div v-if="selectedMenuItems.seeCompanies && selectedMenuItems?.companies">
                                <a class="menu__level-item menu__level-item--footer" href="/leclub/companies">
                                    <BaseIcon legacy name="arrow-right" class="menu__item-sub-icon" />
                                    {{ selectedMenuItems.seeCompanies }}
                                </a>
                                <div class="menu__newsCompanies">
                                    <div>
                                        {{ selectedMenuItems.newsCompanies }}
                                    </div>
                                    <div class="menu__newsCompanies-company">
                                        <a
                                            v-for="company in selectedMenuItems?.companies"
                                            :key="company.id"
                                            class="menu__level-item menu__level-item--link"
                                            :href="`/leclub/companies/details/${company?.slug}/info`"
                                        >
                                            {{ company.name }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="selectedMenuItems.categories" class="px-6">
                            <template v-if="selectedMenuItems.myHubValue">
                                <a :href="`${privateUrl}/hubs/${selectedMenuItems.myHubValue.slug}`">
                                    {{ selectedMenuItems.myHub }} :
                                    {{ selectedMenuItems.myHubValue.name }}
                                </a>
                            </template>
                            <template
                                v-if="
                                    selectedMenuItems.secondariesHubs &&
                                    selectedMenuItems.secondariesHubsValues &&
                                    selectedMenuItems.secondariesHubsValues?.length > 0
                                "
                            >
                                {{ selectedMenuItems.secondariesHubs }} :
                                <a
                                    v-for="hub in selectedMenuItems.secondariesHubsValues"
                                    :key="hub.id"
                                    class="menu__link"
                                    :href="`${privateUrl}/hubs/${hub.slug}`"
                                >
                                    {{ hub.name }}
                                </a>
                            </template>

                            <div v-if="selectedMenuItems.allHubs" class="menu__all-hubs">
                                {{ selectedMenuItems.allHubs }}
                            </div>
                            <div v-for="category in selectedMenuItems?.categories" :key="category.name">
                                <div
                                    class="menu__category-name menu__clickable"
                                    @click="openFolderLocation(category.name)"
                                >
                                    {{ category.name }}
                                </div>
                                <div v-show="groupLocationShow === category.name" class="menu__group-location">
                                    <a
                                        v-for="hub in category.children"
                                        :key="hub.label"
                                        :href="hub.href"
                                        :data-important="hub.label"
                                        class="menu__level-item menu__level-item--link"
                                    >
                                        {{ hub.label }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </BemTransition>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';
    .menu {
        $block-selector: &;
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: 150ms;
        transition-delay: 350ms;

        &--open {
            transition-delay: 0s;
            visibility: visible;
            opacity: 1;
            pointer-events: auto;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--theme-text-color);
            opacity: 0.3;
        }

        &__clickable {
            cursor: pointer;
        }

        &__button {
            min-width: 1em;
            height: 100%;
            justify-content: flex-start;
        }

        &__body {
            position: relative;
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            background: var(--theme-background-color);
            color: var(--theme-text-color);
            transform: translateX(-100%);
            transition: 350ms;
            transition-delay: 150ms;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            #{$block-selector}--open & {
                transform: translateX(0);
                transition-delay: 0s;
            }

            #{$block-selector}--leveled & {
                overflow-y: hidden;
            }
        }
        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: var(--scale-6);
            padding: var(--scale-4) var(--scale-6);
            border-bottom: solid var(--scale-px) var(--theme-color-gray--05);
        }

        &__profile {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 3.75em;
            padding-bottom: 1.875em;
        }

        &__avatar {
            font-size: 0.75em;
            margin-bottom: 0.75em;
        }

        &__name {
            font-weight: bold;
            margin-bottom: 0.625em;
            text-align: center;
        }

        &__position {
            color: var(--theme-text-color--secondary);
            font-size: 0.875em;
            margin-bottom: 1em;
            text-align: center;
        }

        &__link {
            font-weight: bold;
            font-size: 0.875em;
            text-decoration: underline;
            cursor: pointer;
        }

        &__newsCompanies {
            margin-top: 1em;
            &-company {
                padding-left: 1em;
                margin-top: 0.5em;
            }
        }

        &__profile,
        &__footer {
            flex-shrink: 0;
        }

        &__list {
            flex: 1 1 auto;
        }

        &__item {
            border-top: 1px solid var(--theme-border-color);
            padding: 1.5625em;
            min-height: 4.0625em;

            &:last-child {
                border-bottom: 1px solid var(--theme-border-color);
            }

            &--footer {
                display: block;
                padding-top: 0.75em;
                align-items: flex-start;
            }
        }

        &__item-name {
            font-weight: bold;
            margin-bottom: 0.25em;
            display: flex;
            align-items: baseline;
            cursor: pointer;
        }

        &__item-icon {
            font-size: 1.75em;
            margin-right: 0.25em;
        }

        &__item-subname {
            font-size: 0.75em;
        }

        &__item-list {
            padding-top: 1.4375em;
        }

        &__item-sub {
            display: flex;
            align-items: center;
            margin-bottom: 1.5625em;
            cursor: pointer;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__item-sub-icon {
            margin-right: 1em;
        }

        &__item-sub-name {
            font-weight: bold;

            &--link {
                font-weight: normal;
            }
        }

        &__item-sub-description {
            color: var(--theme-text-color--secondary);
            font-size: 0.675em;
        }

        &__item-sub-details {
            &--level {
                padding: 1.5em;
                border-top: 1px solid var(--theme-border-color);
            }
        }

        &__level {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: var(--theme-background-color);
            overflow-x: hidden;
            overflow-y: auto;
            padding-top: 3.375em;
            color: var(--theme-text-color);
        }

        &__level-body {
            padding: 0 1.5em;
            cursor: pointer;
        }

        &__level-item {
            cursor: pointer;
            &--link {
                display: block;
                font-size: 0.875em;
                padding: 0.4375em 0;
                transition: 350ms;
            }

            &--footer {
                display: flex;
                align-items: center;
                font-size: 0.875em;
                transition: 350ms;
                margin-top: 0.75em;
            }
        }

        &__previous {
            color: var(--theme-text-color--secondary);
            font-weight: bold;
            display: flex;
            align-items: center;
            margin-bottom: 1em;
            padding: 0 1.5em;
            cursor: pointer;
        }

        &__previous-icon {
            margin-right: 0.5em;
            font-size: 1em;
            width: 1.75em;
            height: 1.75em;
            border: 0.125em solid var(--theme-text-color--secondary);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
        }

        &__all-hubs {
            padding-bottom: 0.5em;
            padding-top: 1em;
            font-weight: bold;
            &:after {
                content: ' :';
            }
        }

        &__category-name {
            font-size: 0.875em;
            font-weight: bold;
            text-transform: uppercase;
            padding-bottom: 0.5em;
        }
        &__group-location {
            padding-bottom: 15px;
            margin-left: 10px;
        }
        &__pos-relative {
            position: relative;
        }
        &__close {
            display: inline-flex;
            border: none;
            background: none;
            font-size: 1.5em;
            color: var(--theme-text-color);
            cursor: pointer;
        }
    }

    .level {
        &--enter-active,
        &--leave-active {
            transition: transform 350ms;
        }

        &--enter-from,
        &--leave-to {
            transform: translateX(100%);
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "profile": "Voir mon profil",
        "sponsor": {
            "title": "Recommander un contact",
            "subtitle": "Partagez les opportunités d’une communauté d’influence",
            "link": "La communauté des entreprises"
        },
        "back": "Retour",
        "account": {
            "title": "Mon compte",
            "profile": "Mes informations",
            "logout": "Déconnexion"
        }
    }
}
</i18n>
