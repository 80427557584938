<script setup lang="ts">
    import { computed, ref } from 'vue'
    import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
    import BaseButton from '../basics/BaseButton.vue'
    import { ButtonTheme } from '@/vars/ButtonAttr'
    import { useI18n } from 'vue-i18n'
    import { Position, VerticalPosition, HorizontalPosition, PopoverTheme } from '@/types/Popover.type'

    const props = withDefaults(
        defineProps<{
            position?: Position
            forceOpen?: boolean
            hideAction?: boolean
            noMaxWidth?: boolean
            tag?: string
            hoverTrigger?: boolean
            theme: PopoverTheme
        }>(),
        {
            position: () => [VerticalPosition.BOTTOM, HorizontalPosition.LEFT],
            forceOpen: undefined, // Force "undefined" pour le v-if, sinon on risque de ne pas afficher la popover quand l'option est désactivée.
            tag: 'div',
            theme: PopoverTheme.DEFAULT
        }
    )

    const isHoverActive = ref(false)

    const { t } = useI18n()

    const openPopOver = () => {
        isHoverActive.value = true
    }

    const closePopOver = (close) => {
        isHoverActive.value = false
        close()
    }

    const popoverContentClasses = computed(() => {
        const classes = props.position.map((pos) => `popover__content--${pos}`)
        if (props.theme === PopoverTheme.DARK) {
            classes.push('popover__content--dark')
        }
        return classes
    })
</script>

<template>
    <Popover v-slot="{ close }" class="popover" :class="[`popover--${position}`]" :as="tag">
        <div
            @mouseover="() => openPopOver()"
            @mouseleave="
                () => {
                    closePopOver(close)
                }
            "
        >
            <PopoverButton class="popover__trigger" :class="{ 'popover__trigger--inline': tag === 'span' }" :as="tag">
                <slot />
            </PopoverButton>
        </div>

        <Transition
            enter-active-class="appear-enter-active"
            enter-from-class="appear-enter-from"
            enter-to-class="appear-enter-to"
            leave-active-class="appear-leave-active"
            leave-from-class="appear-leave-from"
            leave-to-class="appear-leave-to"
        >
            <PopoverPanel
                v-if="forceOpen ?? true"
                class="popover__content"
                :class="[...popoverContentClasses, !noMaxWidth && ['popover__content--max-width']]"
                :static="hoverTrigger ? isHoverActive : forceOpen ?? false"
            >
                <h6 v-if="$slots.title" class="popover__title">
                    <slot name="title"></slot>
                </h6>

                <p v-if="$slots.text" class="popover__text">
                    <slot name="text" />
                </p>

                <div v-if="!hideAction" class="popover__actions">
                    <slot name="action">
                        <BaseButton class="popover__button" :theme="ButtonTheme.SIMPLE" reverse @click="close">
                            {{ t('ok') }}
                        </BaseButton>
                    </slot>
                </div>
            </PopoverPanel>
        </Transition>
    </Popover>
</template>

<style lang="scss" scoped>
    .popover {
        --popover-space: 1em;
        --bg-color: var(--theme-text-color);

        position: relative;

        &__title {
            font-size: 0.875em;
            font-weight: bold;
            margin-bottom: var(--scale-2);
        }

        &__text {
            font-size: 0.75em;
            margin-bottom: var(--scale-2);
        }

        &__button {
            padding-top: 0.5em;
            padding-bottom: 0.5em;
        }

        &__content {
            $content: &;
            font-size: 1rem;
            position: absolute;
            z-index: 10;
            background-color: var(--bg-color);
            color: var(--theme-primary-text-color);
            padding: 1em;
            border-radius: 0.5em;
            width: max-content;

            &--dark {
                background-color: var(--theme-color-legacy);
                color: var(--theme-color-nuage);
                --bg-color: var(--theme-color-legacy);
            }

            &--max-width {
                max-width: 15.625em;
            }
            &::before {
                --arrow-size: 0.5em;
                --arrow-color: var(--bg-color);

                content: '';
                display: inline-block;
                height: 0;
                width: 0;
                position: absolute;
            }

            // VERTICAL
            &--top {
                transform: translateY(-100%);
                top: -0.5em;

                &::before {
                    bottom: calc(var(--arrow-size) * -1);
                    border-top: var(--arrow-size) solid var(--arrow-color);
                    border-right: var(--arrow-size) solid transparent;
                    border-left: var(--arrow-size) solid transparent;
                }
            }

            &--vcenter {
                top: 50%;
                transform: translateY(-50%);
            }

            &--bottom {
                top: calc(var(--popover-space) * 2);

                &::before {
                    top: calc(var(--arrow-size) * -1);
                    border-bottom: var(--arrow-size) solid var(--arrow-color);
                    border-right: var(--arrow-size) solid transparent;
                    border-left: var(--arrow-size) solid transparent;
                }
            }

            // Horizontal
            &--left {
                left: 0;
            }

            &--right {
                right: 0;
            }

            &--hcenter {
                translate: -50%;
                left: 50%;
            }
        }

        // Exceptions & Arrows
        .popover__content--vcenter.popover__content--left {
            translate: calc(-100% - var(--popover-space));
        }

        .popover__content--bottom.popover__content--right {
            &::before {
                top: calc(var(--arrow-size) * -1);
                right: calc(var(--arrow-size) * 2);
                border-bottom: var(--arrow-size) solid var(--arrow-color);
                border-right: var(--arrow-size) solid transparent;
                border-left: var(--arrow-size) solid transparent;
            }
        }

        .popover__content--bottom.popover__content--hcenter {
            &::before {
                top: calc(var(--arrow-size) * -1);
                left: 50%;
                translate: -50%;
                border-bottom: var(--arrow-size) solid var(--arrow-color);
                border-right: var(--arrow-size) solid transparent;
                border-left: var(--arrow-size) solid transparent;
            }
        }

        .popover__content--top.popover__content--hcenter {
            &::before {
                bottom: calc(var(--arrow-size) * -1);
                left: 50%;
                translate: -50%;
                border-top: var(--arrow-size) solid var(--arrow-color);
                border-right: var(--arrow-size) solid transparent;
                border-left: var(--arrow-size) solid transparent;
            }
        }

        .popover__content--top.popover__content--right {
            &::before {
                bottom: calc(var(--arrow-size) * -1);
                right: calc(var(--arrow-size) * 2);
                translate: -50%;
                border-top: var(--arrow-size) solid var(--arrow-color);
                border-right: var(--arrow-size) solid transparent;
                border-left: var(--arrow-size) solid transparent;
            }
        }

        .popover__content--vcenter.popover__content--right {
            translate: calc(100% + var(--popover-space));

            &::before {
                top: 50%;
                left: calc(var(--arrow-size) * -1);
                translate: 0 -50%;
                border-top: var(--arrow-size) solid transparent;
                border-bottom: var(--arrow-size) solid transparent;
                border-right: var(--arrow-size) solid var(--arrow-color);
            }
        }

        .popover__content--vcenter.popover__content--left {
            &::before {
                top: 50%;
                right: calc(var(--arrow-size) * -1);
                translate: 0 -50%;
                border-top: var(--arrow-size) solid transparent;
                border-bottom: var(--arrow-size) solid transparent;
                border-left: var(--arrow-size) solid var(--arrow-color);
            }
        }

        &__actions {
            display: flex;
            justify-content: flex-end;
        }
    }

    :deep(.popover__trigger) {
        display: flex;
    }

    :deep(.popover__trigger--inline) {
        display: inline-flex;
    }

    .appear {
        &-enter-active,
        &-leave-active {
            transition: all 350ms ease-in-out;
        }

        &-enter-from,
        &-leave-to {
            opacity: 0;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "ok": "Ok"
    },
    "en": {
        "ok": "Ok"
    }
}
</i18n>
