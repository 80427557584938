import { ref } from 'vue'
import EkkoSdk from '@ekko-chat/ekko-js-sdk'
import {
    EkkoChannel,
    EkkoChannelResquestParams,
    EkkoCustomMessageData,
    EkkoError,
    EkkoInstance,
    EkkoMessage,
    EkkoUser,
    MessageLoader
} from '@/types/Ekko.type'
import MessagingApi from '../api/Messaging.api'

const ekkoInstance = ref<EkkoInstance | null>(null)
const EKKO_INSTANCE_NOT_INIT_ERROR = new Error('You must init Ekko before running this function.')

export default function useEkko() {
    const messageLoader = ref<MessageLoader | null>(null)

    const connectToEkko = async (idFF: string, ekkoAccessToken: string): Promise<EkkoUser | undefined> => {
        const instance = ekkoInstance

        if (!ekkoInstance.value) {
            instance.value = new EkkoSdk({
                app_id: import.meta.env.VITE_EKKO_APP_ID,
                server: 'chat-eu-1.ekko.chat'
            })
        }

        if (instance.value?.connected && instance.value?.user) {
            return instance.value.user
        }

        try {
            return await instance.value?.connect(idFF, ekkoAccessToken, null, () => null)
        } catch (e) {
            const typedError = e as EkkoError

            if (typedError.code === 'USER_ALREADY_CONNECTED') {
                return instance.value?.user
            }

            throw e
        }
    }

    const disconnect = () => {
        if (ekkoInstance.value?.connected) {
            ekkoInstance.value?.disconnect?.()
        }
    }

    const sendMessage = (ekkoChannel: EkkoChannel, message: string, customType?: string): Promise<EkkoMessage> => {
        return ekkoChannel.sendMessageWithCustomType('text', message, customType)
    }

    const sendCustomMessage = (ekkoChannel: EkkoChannel, customMessage: EkkoCustomMessageData) => {
        return ekkoChannel.sendCustomMessage(customMessage)
    }

    const sendFileMessage = async (ekkoChannel: EkkoChannel, file: File, message = '') => {
        const { file: fileUploaded } = await MessagingApi.uploadFile(file)
        return ekkoChannel.sendFileMessageWithData(fileUploaded.url_absolute, file.name, file.type, file.size, message)
    }

    const getPrivateChannelList = (params: EkkoChannelResquestParams = {}): Promise<EkkoChannel[]> => {
        if (!ekkoInstance.value) {
            throw EKKO_INSTANCE_NOT_INIT_ERROR
        }

        if (!params.search) {
            delete params.search // Dans le cas où: search: ''
        }

        return ekkoInstance.value?.getSubscribedPrivateChannels({
            ...params,
            limit: 250
        })
    }

    const getPublicChannelList = (params?: EkkoChannelResquestParams): Promise<EkkoChannel[]> => {
        if (!ekkoInstance.value) {
            throw EKKO_INSTANCE_NOT_INIT_ERROR
        }

        return ekkoInstance.value.getSubscribedPublicsChannels(params)
    }

    const getChannelDetailsFromUrl = (channelId: string): Promise<EkkoChannel> => {
        if (!ekkoInstance.value) {
            throw EKKO_INSTANCE_NOT_INIT_ERROR
        }

        return ekkoInstance.value.getChannel(channelId)
    }

    const getChannelDetailsFromUserIdList = (
        userIdList: string[],
        customType: 'connection' | 'dm' = 'dm',
        distinct = true
    ): Promise<EkkoChannel> => {
        if (!ekkoInstance.value) {
            throw EKKO_INSTANCE_NOT_INIT_ERROR
        }

        return ekkoInstance.value.createChannelWithUsersId(userIdList, {
            distinct,
            custom_type: customType
        })
    }

    const leaveChannel = (channel: Pick<EkkoChannel, 'leave'>) => {
        return channel.leave()
    }

    const getChannelMessages = async (channel: EkkoChannel, shouldResetMessageList = false): Promise<EkkoMessage[]> => {
        if (!messageLoader.value || shouldResetMessageList) {
            messageLoader.value = channel.createMessageLoader()
            messageLoader.value.reverse = true
        }

        return await messageLoader.value.load()
    }

    const enterEkkoChannel = async (channel: EkkoChannel): Promise<boolean> => {
        if (channel.connected) {
            return false
        }

        await channel.enter()
        return true
    }

    const markEkkoChannelAsRead = async (channel: EkkoChannel) => {
        await enterEkkoChannel(channel)
        if (!channel) {
            console.warn('No channel provided')
            return null
        }

        channel.markAsRead()
        getNewUnreadMessageCount()
    }

    const getNewUnreadMessageCount = async (): Promise<number> => {
        if (!ekkoInstance.value) {
            throw EKKO_INSTANCE_NOT_INIT_ERROR
        }

        try {
            if (!ekkoInstance.value.connected) {
                return Promise.resolve(0)
            }

            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { unread_count } = await ekkoInstance.value.getUnreadMessages()
            // dispatch(setEkkoUnreadMessageCount(unread_count))
            // setBadge(unread_count)
            return unread_count
        } catch (e) {
            console.error(e)
            return 0
        }
    }

    const deleteEkkoMessage = (channel: EkkoChannel, message: EkkoMessage): Promise<EkkoMessage> => {
        return channel.deleteMessage(message)
    }

    return {
        ekkoInstance,
        connectToEkko,
        disconnect,
        sendMessage,
        sendCustomMessage,
        sendFileMessage,
        getPublicChannelList,
        getPrivateChannelList,
        getChannelMessages,
        getNewUnreadMessageCount,
        getChannelDetailsFromUrl,
        getChannelDetailsFromUserIdList,
        enterEkkoChannel,
        markEkkoChannelAsRead,
        deleteEkkoMessage,
        leaveChannel
    }
}
