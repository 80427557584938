import SharedStorage from '@/libs/core/SharedStorage'
import { AuthAttr } from '@/vars/AuthAttr'
import { InternalAxiosRequestConfig } from 'axios'

export async function checkTokenInterceptor(request: InternalAxiosRequestConfig) {
    const token =
        SharedStorage.get(import.meta.env.VITE_COOKIE_NAME || AuthAttr.JWT_TOKEN) || import.meta.env.VITE_DEV_TOKEN

    if (token && request.headers && request.url !== '/token') {
        request.headers.Authorization = `Bearer ${token}`
    }

    return request
}

export default checkTokenInterceptor
