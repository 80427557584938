<script setup lang="ts">
    import { computed, ref } from 'vue'
    import { AvatarSize } from '@/vars/AvatarAttr'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import { RouteLocation, RouteLocationNamedRaw } from 'vue-router'
    import SpaRouterLink from '../navigations/SpaRouterLink.vue'

    const props = withDefaults(
        defineProps<{
            src?: string
            size: AvatarSize
            alt?: string
            isCompany?: boolean
            isAnonymous?: boolean
            loading?: boolean
            href?: string
            to?: RouteLocationNamedRaw | RouteLocation
            target?: '_blank' | undefined
        }>(),
        {
            src: undefined,
            size: AvatarSize.NORMAL,
            alt: undefined
        }
    )

    const isLoadingImage = ref(false)
    const hasFailedLoadingImage = ref(false)

    const avatarIcon = computed(() => {
        if (props.isAnonymous) {
            return 'shield_person'
        }

        if (props.isCompany) {
            return 'home_work'
        }

        return 'account-circle'
    })

    const onErrorLoadingImage = () => {
        isLoadingImage.value = false
        hasFailedLoadingImage.value = true
    }

    const linkBindings = computed(() => {
        if (props.href) {
            return {
                is: 'a',
                bindings: {
                    href: props.href,
                    target: props.target
                }
            }
        }

        if (props.to) {
            return {
                is: SpaRouterLink,
                bindings: {
                    to: props.to,
                    target: props.target
                }
            }
        }

        return { is: 'div' }
    })
</script>

<template>
    <div>
        <component :is="linkBindings.is" v-bind="linkBindings.bindings">
            <div
                v-if="loading"
                class="avatar bg-gray-5"
                :class="[`avatar--${size}`, { 'avatar--company': isCompany }]"
            ></div>
            <img
                v-else-if="
                    src && !src.endsWith('/avatar.jpg') && !isAnonymous && !hasFailedLoadingImage && !isLoadingImage
                "
                class="avatar"
                :class="[`avatar--${size}`, { 'avatar--company': isCompany, 'avatar--loading': isLoadingImage }]"
                :src="src"
                :alt="alt"
                loading="lazy"
                @loadstart="isLoadingImage = true"
                @load="isLoadingImage = false"
                @error="onErrorLoadingImage"
            />
            <div
                v-else
                class="avatar avatar--empty"
                :class="[`avatar--${size}`, { 'avatar--company': isCompany, 'avatar--user': !isCompany }]"
            >
                <BaseIcon class="avatar__icon" :name="avatarIcon" />
            </div>
        </component>
        <slot name="status-icon"></slot>
    </div>
</template>

<style scoped lang="scss">
    .avatar {
        $block: &;
        --avatar-size: 4rem;
        position: relative;

        display: block;
        width: var(--avatar-size);
        min-width: var(--avatar-size);
        height: var(--avatar-size);
        min-height: var(--avatar-size);
        object-fit: cover;
        border-radius: 999rem;

        &--loading {
            background: var(--theme-color-gray--06);
        }

        &--mini {
            --avatar-size: 1.875rem;
        }

        &--small {
            --avatar-size: 3rem;
        }

        &--large {
            --avatar-size: 7.5rem;
        }

        &--empty {
            display: flex;
            justify-content: center;
        }

        &--user {
            background: var(--theme-color-flagship--10);
            color: var(--theme-text-color);
        }

        &--company {
            display: flex;
            justify-content: center;
            border-radius: 0.25rem;
            padding: var(--scale-2r);
            color: var(--theme-color-gray--04);
            box-shadow: 0 0 0.1875rem var(--theme-shadow-color);
            background-color: var(--theme-color-nuage);
            object-fit: contain;
        }

        &__icon {
            --icon-size: calc(var(--avatar-size) / 2);
            font-size: var(--icon-size);
            color: inherit;

            #{$block}--mini & {
                --icon-size: calc(var(--avatar-size) / 1.5);
            }
        }
    }
</style>
