<script setup lang="ts">
    import { computed } from 'vue'
    import { RouteLocation, RouteLocationNamedRaw } from 'vue-router'
    import SpaRouterLink from '../navigations/SpaRouterLink.vue'
    import { LinkTheme } from '@/vars/LinkAttr'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'

    const props = withDefaults(
        defineProps<{
            noUnderline?: boolean
            disabled?: boolean
            href?: string
            to?: RouteLocationNamedRaw | RouteLocation
            target?: '_blank' | undefined
            reversed?: boolean
            theme?: LinkTheme
            testId?: string
        }>(),
        {
            theme: LinkTheme.DEFAULT
        }
    )
    const linkBindings = computed(() => {
        if (props.href) {
            return {
                is: 'a',
                bindings: {
                    href: props.href,
                    target: props.target
                }
            }
        }

        if (props.to) {
            return {
                is: SpaRouterLink,
                bindings: {
                    to: props.to,
                    target: props.target
                }
            }
        }

        return { is: 'div' }
    })
</script>

<template>
    <div>
        <Component
            :is="linkBindings.is"
            v-bind="linkBindings.bindings"
            class="link"
            :class="[
                {
                    'link--no-underline': noUnderline,
                    'link--disabled': disabled,
                    'link--reversed': reversed
                },
                `link--theme-${theme}`
            ]"
            :data-testId="testId"
        >
            <slot />
            <BaseIcon v-if="theme === LinkTheme.IMPORTANT" class="link__icon" name="arrow_forward" />
        </Component>
    </div>
</template>

<style scoped lang="scss">
    .link {
        $block: &;

        cursor: pointer;
        color: var(--theme-link-color-text);
        text-decoration: underline;

        &:not(.link--disable):hover {
            opacity: 0.75;
        }

        &--disabled {
            color: var(--theme-link-color-disable);
            cursor: not-allowed;
        }

        &--theme-important {
            display: inline-flex;
            gap: var(--scale-2r);
            color: var(--theme-color-royale);
            font-weight: 500;
            text-decoration: none;
        }

        &--no-underline {
            text-decoration: none;
        }

        &--reversed {
            color: var(--theme-primary-text-color);
        }

        &__icon {
            font-size: 1.25em;
            transition: translate 200ms ease-in-out;

            #{$block}:hover & {
                translate: 30%;
            }
        }
    }
</style>
