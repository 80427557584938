<script setup lang="ts">
    import { ref, watch } from 'vue'
    import { useI18n } from 'vue-i18n'
    import gsap from 'gsap'
    import { AboutRouteName } from '@/vars/RouteName'
    import LogoMedia from '@/components/ui/medias/LogoMedia.vue'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import usePublicHeaderNavigation, {
        MainNavigationItem,
        MenuCategory
    } from '@/libs/compositions/usePublicHeaderNavigation'
    import { nextTick } from 'process'

    import MobileNavigationMenuTemplate from '@/components/templates/public/MobileNavigationMenuTemplate.vue'
    import MenuMobileLink from './MenuMobileLink.vue'
    import { useRoute } from 'vue-router'
    import MainMenuEntry from '@/components/layouts/common/__partials__/MainMenuEntry.vue'
    import HeaderMobileNavigationTransverse from '@/components/layouts/common/header/navigations/HeaderMobileNavigationTransverse.vue'

    const showMenu = defineModel<boolean>()
    const { mainNavigation } = usePublicHeaderNavigation()
    const { t } = useI18n()
    const route = useRoute()

    const selectedMainEntry = ref<MainNavigationItem | null>(null)
    const selectedCategory = ref<MenuCategory | null>(null)
    const deepMenuFrom = ref<'right' | 'left'>('right')

    watch(selectedCategory, (newSelectedCategory, oldSelectedCategory) => {
        const newCategoryEl = document.querySelector(`[data-category="${newSelectedCategory?.key}"]`)
        const oldCategoryEl = document.querySelector(`[data-category="${oldSelectedCategory?.key}"]`)

        if (newCategoryEl) {
            animateElement(newCategoryEl, true)
        }

        if (oldCategoryEl) {
            animateElement(oldCategoryEl, false)
        }
    })

    watch(showMenu, () => {
        if (showMenu.value) {
            window.$crisp?.push(['do', 'chat:hide'])
        } else {
            window.$crisp?.push(['do', 'chat:show'])
        }
    })

    const animateElement = (el: Element, open: boolean) => {
        const animationOptions = {
            duration: selectedMainEntry.value?.categories?.length === 1 ? 0 : 0.5,
            ease: 'power2'
        }

        if (open) {
            gsap.to(el, { ...animationOptions, height: 'auto', opacity: 1 })
        } else {
            gsap.to(el, { ...animationOptions, height: 0, opacity: 0 })
        }
    }

    const onCloseMenu = () => {
        deepMenuFrom.value = 'left'

        // On attend le prochain rendu pour être sûr de fermer tous les menus vers la gauche
        nextTick(() => {
            selectedMainEntry.value = null
            selectedCategory.value = null
            showMenu.value = false
        })
    }

    const onBackToPrimaryMenu = () => {
        selectedMainEntry.value = null
        selectedCategory.value = null
    }

    const onSelectMainEntry = (entry: MainNavigationItem) => {
        deepMenuFrom.value = 'right'
        selectedMainEntry.value = entry
        nextTick(() => {
            // Next tick pour attendre que le second menu s'ouvre
            selectedCategory.value = entry.categories.length === 1 ? entry.categories[0] : null
        })
    }

    watch(route, onCloseMenu)
</script>

<template>
    <div>
        <!-- Premier niveau -->
        <MobileNavigationMenuTemplate v-model="showMenu" class="menu" from="left">
            <template #header>
                <SpaRouterLink :to="{ name: AboutRouteName.WEWEB_ABOUT }">
                    <LogoMedia ephemeral dark />
                </SpaRouterLink>
                <button type="button" class="menu__close" @click="onCloseMenu">
                    <BaseIcon name="close" />
                </button>
            </template>
            <template #default>
                <div class="menu__primary">
                    <main>
                        <MainMenuEntry
                            v-for="entry in mainNavigation"
                            :key="entry.key"
                            :label="entry.label"
                            @click="onSelectMainEntry(entry)"
                        />
                    </main>
                    <HeaderMobileNavigationTransverse />
                </div>
            </template>
        </MobileNavigationMenuTemplate>

        <!-- Second niveau -->
        <MobileNavigationMenuTemplate
            :model-value="!!selectedMainEntry"
            :from="deepMenuFrom"
            @update:model-value="selectedMainEntry = null"
        >
            <template #header>
                <button class="menu__back" type="button" @click="onBackToPrimaryMenu">
                    <BaseIcon class="menu__back-icon" name="arrow_back" />
                    {{ t('back') }}
                </button>
                <button type="button" class="menu__close" @click="onCloseMenu">
                    <BaseIcon name="close" />
                </button>
            </template>
            <template v-if="selectedMainEntry" #default>
                <main class="menu__nav">
                    <h3 class="px-6 py-4">{{ selectedMainEntry.label }}</h3>
                    <div
                        v-for="category in selectedMainEntry.categories"
                        :key="category.key"
                        class="menu__category menu__category--deep"
                    >
                        <MainMenuEntry
                            v-show="selectedMainEntry.categories.length > 1"
                            :label="category.label"
                            :expanded="selectedCategory === category"
                            no-border
                            @click="selectedCategory = selectedCategory === category ? null : category"
                        />
                        <div class="menu__category-links" :data-category="category?.key || ''">
                            <Component
                                :is="link?.to ? SpaRouterLink : 'a'"
                                v-for="(link, i) in category.items"
                                :key="link.title"
                                v-bind="{
                                    ...(link.to && { to: link?.to }),
                                    ...(link.href && { href: link?.href })
                                }"
                            >
                                <MenuMobileLink
                                    class="menu__link"
                                    :class="{ 'menu__link--first': i === 0 }"
                                    :link="link"
                                />
                            </Component>
                        </div>
                    </div>
                </main>
            </template>
        </MobileNavigationMenuTemplate>
    </div>
</template>

<style scoped lang="scss">
    $borderBottom: solid var(--scale-px) var(--theme-color-gray--04);
    .menu {
        &__primary {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }

        &__your-universes {
            padding: var(--scale-6);
        }

        &__universes {
            margin-bottom: 0;
            margin-top: auto;
        }

        &__universe {
            background-color: var(--theme-color-legacy);
            color: white;
            &--active {
                background-color: var(--theme-color-royale);
            }
        }

        &__universe-details {
            height: 0; // For animation
            opacity: 0;
            overflow: hidden;
        }

        &__universe-links {
            list-style: none;
            padding-left: var(--scale-2);
        }

        &__category {
            border-bottom: $borderBottom;

            &--deep {
                padding-left: var(--scale-10);
            }
        }

        &__category-links {
            height: 0; // For animation
            overflow: hidden;
        }

        &__link {
            &--first {
                padding-top: 0;
            }
        }

        &__back {
            display: flex;
            align-items: center;
            gap: var(--scale-2);
            background: none;
            border: none;
            color: var(--theme-color-royale);
            font-size: 1.125em;
            cursor: pointer;
        }

        &__back-icon {
            font-size: 1.125em;
        }

        &__close {
            display: inline-flex;
            border: none;
            background: none;
            font-size: 1.5em;
            color: var(--theme-text-color);
            cursor: pointer;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "back": "Retour",
        "your_universes": "Vos espaces privés"
    },
    "en": {
        "back": "Back",
        "your_universes": "Your private spaces"
    }
}
</i18n>
