import EmptyLayout from '@/components/layouts/EmptyLayout.vue'
import ApplyLayout from '@/components/layouts/leclub/ApplyLayout.vue'
import AccountLayout from '@/components/layouts/AccountLayout.vue'
import RetryPaymentLayout from '@/components/layouts/RetryPaymentLayout.vue'
import SimplisticLayout from '@/components/layouts/SimplisticLayout.vue'
import {
    checkAccessApply,
    requireTalentOnboarding,
    requiredApplyPlan,
    requiredAuthentification,
    canAccessAsLost,
    redirectPublicClubPlanIfNotLoggedIn,
    redirectApplyLost,
    requiredRoleLeClub
} from '@/libs/middlewares/auth'
import { RouteName, CompaniesRouteName, EventRouteName } from '@/vars/RouteName'
import type { RouteRecordRaw } from 'vue-router'
import PaymentConfirmationLayout from '@/components/layouts/network/PaymentConfirmationLayout.vue'
import { BusinessLineName } from '@/vars/AuthAttr'
import { SpaName } from '@/vars/SpaAttr'
import { Lang } from '@/types/Lang.type'
import AsideTalentProfileLayout from '@/components/layouts/network/jobs/AsideTalentProfileLayout.vue'
import aboutRoutes from './common/about-routes'
import companiesRoutes from './common/companies-routes'
import whatsappRoutes from './common/whatsapp-routes'
import eventsRoutes from './common/events-routes'
import authRoutes from './common/auth-routes'
import inboxRoutes from './common/inbox-routes'
import letterManagementRoutes from './common/letter-management-routes'
import { useAuthStore } from '@/libs/stores/auth.store'
import { redirectToPodcastIfSlugHasPodcast } from '@/libs/middlewares/resources'
import { redirectToHomeIfAlreadyConnected } from '@/libs/middlewares/beforeEnter/auth'
import benefitRoutes from '@/routes/common/benefit-routes'

const routes: RouteRecordRaw[] = [
    ...aboutRoutes,
    ...companiesRoutes,
    ...whatsappRoutes,
    ...eventsRoutes,
    ...authRoutes,
    ...inboxRoutes,
    ...letterManagementRoutes,
    ...benefitRoutes,
    {
        path: '/',
        alias: '/index.html',
        name: RouteName.HOME,
        component: () => import('@/views/network/home-page/HomePage.v2.vue'), // Chargement de "LoggedHomepage" si on est connecté directement depuis ce composant
        meta: {
            context: SpaName.LENETWORK,
            forceLang: Lang.FR,
            title: 'Frenchfounders - Le réseau business francophone international',
            hasGridBackground: true
        }
    },
    {
        // On utilise une regex pour la liste des toutes les villes pour lesquelles on a une landing page custom
        // ex: /:citySlug(londres|paris|new-york)
        path: '/:citySlug(londres|montreal|dubai|madrid|new-york|apac|france)',
        name: RouteName.CITY_LANDING,
        component: () => import('@/views/network/city-landing-page/CityLandingPage.vue'),
        meta: {
            forceLang: Lang.FR,
            hideWhatsNew: true
        }
    },
    {
        path: '/home',
        meta: {
            forceLang: Lang.FR,
            hasGridBackground: true
        },
        children: [
            {
                path: 'ceo',
                name: RouteName.HOME_CEO,
                component: () => import('@/views/network/homepage-ceo/HomepageCeo.vue'),
                meta: {
                    title: `Frenchfounders - CEO`
                }
            },
            {
                path: 'entrepreneur',
                name: RouteName.HOME_ENTREPRENEUR,
                component: () => import('@/views/network/homepage-entrepreneur/HomepageEntrepreneur.vue'),
                meta: {
                    title: `Frenchfounders - Entrepreneur`
                }
            },
            {
                path: 'manager',
                name: RouteName.HOME_MANAGER,
                component: () => import('@/views/network/homepage-manager/HomepageManager.vue'),
                meta: {
                    title: `Frenchfounders - Manager`
                }
            },
            {
                path: 'talent',
                name: RouteName.HOME_TALENT,
                component: () => import('@/views/network/homepage-talent/HomepageTalent.vue'),
                meta: {
                    title: `Frenchfounders - Jeune Talent`
                }
            }
        ]
    },
    {
        path: '/confirmation',
        name: RouteName.PAYMENT_CONFIRMATION,
        component: () => import('@/views/payments/confirmation/PaymentConfirmation.vue'),
        meta: {
            layout: PaymentConfirmationLayout,
            hideWhatsNew: true
        }
    },
    {
        path: '/networking/:slug',
        name: EventRouteName.EVENT_NETWORKING_SINGLE,
        component: () => import('@/views/events/networking-single/NetworkingSingle.vue'),
        meta: {
            context: SpaName.LENETWORK
        }
    },
    {
        path: '/jobs/search',
        alias: '/jobs',
        name: RouteName.JOB_LIST_SELECTION,
        component: () => import('@/views/jobs/search/JobSearchPage.vue'),
        meta: {
            layout: AsideTalentProfileLayout,
            context: SpaName.LENETWORK
        }
    },
    {
        path: '/jobs',
        component: () => import('@/views/jobs/board/JobBoardPage.vue'),
        meta: {
            middleware: [requiredAuthentification]
        },
        children: [
            {
                path: 'pending',
                name: RouteName.JOB_LIST_PENDING,
                component: () => import('@/views/jobs/board/tabs/JobListTab.vue')
            },
            {
                path: 'accepted',
                name: RouteName.JOB_LIST_ACCEPTED,
                component: () => import('@/views/jobs/board/tabs/JobListTab.vue')
            },
            {
                path: 'processed',
                name: RouteName.JOB_LIST_PROCESSED,
                component: () => import('@/views/jobs/board/tabs/JobListTab.vue')
            },
            {
                path: 'declined',
                name: RouteName.JOB_LIST_DECLINED,
                component: () => import('@/views/jobs/board/tabs/JobListTab.vue')
            }
        ]
    },
    {
        path: '/job/:slug',
        name: RouteName.JOB_SINGLE,
        component: () => import('@/views/jobs/jobs-single/JobSingle.vue'),
        meta: {
            context: SpaName.LENETWORK
        }
    },
    {
        path: '/callback/stripe/:result',
        name: RouteName.CALLBACK_STRIPE,
        component: () => import('@/views/callback/StripeCallback.vue'),
        meta: {
            layout: EmptyLayout,
            context: SpaName.LENETWORK,
            hideWhatsNew: true
        }
    },
    {
        path: '/talent/profile',
        name: RouteName.TALENT_PROFILE,
        component: () => import('@/views/talents/TalentProfile.vue'),
        meta: {
            middleware: [requiredAuthentification, requireTalentOnboarding],
            context: SpaName.LENETWORK,
            onlyContentOnApp: true
        }
    },
    {
        path: '/talent/onboarding',
        name: RouteName.TALENT_ONBOARDING,
        component: () => import('@/views/network/talents/onboarding/TalentOnboarding.vue'),
        meta: {
            middleware: [requiredAuthentification],
            context: SpaName.LENETWORK,
            onlyContentOnApp: true,
            layoutProps: { hideMenu: true }
        }
    },
    {
        path: '/resources',
        component: () => import('@/views/resources/ResourcePage.vue'),
        meta: {
            context: SpaName.LENETWORK
        },
        children: [
            {
                path: '',
                name: RouteName.RESOURCES_LIST,
                component: () => import('@/views/resources/tabs/ResourceMostRecentTab.vue')
            },
            {
                path: 'articles',
                name: RouteName.RESOURCES_LIST_ARTICLES,
                component: () => import('@/views/resources/tabs/ResourceListTab.vue')
            },
            {
                path: 'videos',
                name: RouteName.RESOURCES_LIST_VIDEOS,
                component: () => import('@/views/resources/tabs/ResourceListTab.vue')
            },
            {
                path: 'podcasts',
                name: RouteName.RESOURCES_LIST_PODCASTS,
                component: () => import('@/views/resources/tabs/ResourceListTab.vue')
            }
        ]
    },
    {
        path: '/article/:slug',
        name: RouteName.ARTICLE_SINGLE,
        component: () => import('@/views/resources/resources-single/ResourceSingle.vue'),
        meta: {
            context: SpaName.LENETWORK
        }
    },
    {
        path: '/video/:slug',
        name: RouteName.VIDEO_SINGLE,
        component: () => import('@/views/resources/resources-single/ResourceSingle.vue'),
        meta: {
            context: SpaName.LENETWORK,
            middleware: [redirectToPodcastIfSlugHasPodcast]
        }
    },
    {
        path: '/podcast/:slug',
        name: RouteName.PODCAST_SINGLE,
        component: () => import('@/views/resources/resources-single/ResourceSingle.vue'),
        meta: {
            context: SpaName.LENETWORK
        }
    },
    {
        path: '/avantages',
        name: RouteName.ADVANTAGE,
        component: () => import('@/views/advantages/AdvantagePage.vue'),
        meta: {
            middleware: [requiredAuthentification],
            context: SpaName.LENETWORK
        }
    },
    {
        path: '/members',
        name: RouteName.MEMBERS_LIST,
        component: () => import('@/views/members/MemberListPage.vue'),
        meta: {
            middleware: [requiredAuthentification],
            context: SpaName.LENETWORK
        }
    },
    {
        path: '/explore',
        name: RouteName.EXPLORE,
        component: () => import('@/views/auth/explore/ExplorePage.vue'),
        beforeEnter: () => {
            window.location.href = `${import.meta.env.VITE_APP_BASE_URL}`
        },
        meta: {
            layout: ApplyLayout,
            middleware: [requiredAuthentification],
            context: SpaName.LENETWORK,
            layoutProps: { showBatchBanner: false }
        }
    },
    {
        path: '/club/plans',
        name: RouteName.APPLY_CLUB_PLAN,
        component: () => import('@/views/applies/leclub/ChoosePlan/ChoosePlan.vue'), // to change
        meta: {
            middleware: [redirectPublicClubPlanIfNotLoggedIn, requiredAuthentification, canAccessAsLost],
            forceLang: Lang.FR,
            layoutProps: { showBatchBanner: false },
            hideWhatsNew: true
        }
    },
    {
        path: '/leclub/apply',
        name: RouteName.APPLY_LECLUB,
        component: () => import('@/views/applies/leclub/ApplyLeClub.vue'),
        meta: {
            layout: ApplyLayout,
            middleware: [requiredAuthentification, checkAccessApply, requiredApplyPlan, redirectApplyLost],
            forceLang: Lang.FR,
            hideWhatsNew: true
        }
    },
    {
        path: '/leclub-for-bpi/apply',
        name: RouteName.APPLY_LECLUB_FOR_BPI,
        component: () => import('@/views/applies/leclub/ApplyLeClub.vue'),
        meta: {
            layout: ApplyLayout,
            forceLang: Lang.FR,
            hideWhatsNew: true
        }
    },
    {
        path: '/leclub-ten-years/apply',
        name: RouteName.APPLY_LECLUB_FOR_OPE_TEN_YEARS,
        component: () => import('@/views/applies/leclub/ApplyLeClub.vue'),
        meta: {
            layout: ApplyLayout,
            forceLang: Lang.FR,
            hideWhatsNew: true
        }
    },
    {
        path: '/leclub/welcome-back/apply', // opération apply Lost 2024
        redirect: {
            name: RouteName.ACCOUNT_MEMBERSHIP
        }
    },
    {
        path: '/leclub/lost/apply',
        name: RouteName.APPLY_LECLUB_LOST,
        component: () => import('@/views/applies/leclub/apply-lost/ApplyLostLeClub.vue'),
        meta: {
            layout: EmptyLayout,
            middleware: [requiredAuthentification, checkAccessApply, requiredApplyPlan, canAccessAsLost],
            forceLang: Lang.FR,
            hideWhatsNew: true
        }
    },
    {
        path: '/leclub/lost/apply/finished',
        name: RouteName.APPLY_LECLUB_LOST_FINISHED,
        component: () => import('@/views/applies/leclub/apply-lost/apply-finished/ApplyLostLeClubFinished.vue'),
        meta: {
            layout: EmptyLayout,
            middleware: [requiredAuthentification],
            forceLang: Lang.FR,
            hideWhatsNew: true
        }
    },
    {
        path: '/retry-payment/:paymentId',
        name: RouteName.RETRY_PAYMENT,
        component: () => import('@/views/retry-payment/RetryPaymentPage.vue'),
        meta: {
            layout: RetryPaymentLayout,
            middleware: [requiredAuthentification],
            hideWhatsNew: true
        }
    },
    {
        path: '/leclub/apply/finished',
        name: RouteName.APPLY_CLUB_FINISHED,
        component: () => import('@/views/applies/leclub/apply-finished/FinishedApplyLeClub.vue'),
        meta: {
            layout: EmptyLayout,
            middleware: [requiredAuthentification, checkAccessApply, requiredApplyPlan],
            forceLang: Lang.FR,
            hideWhatsNew: true
        }
    },
    {
        path: '/leclub/apply/plan/:planKey',
        redirect: (to) => {
            return {
                name: RouteName.APPLY_LECLUB,
                query: { planKey: to.params.planKey }
            }
        }
    },
    {
        path: '/leclub/apply/partnership/:partnershipSlug',
        redirect: (to) => {
            return {
                name: RouteName.APPLY_LECLUB,
                query: { partnershipSlug: to.params.partnershipSlug }
            }
        }
    },
    {
        path: '/company',
        redirect: (to) => ({
            ...to,
            name: CompaniesRouteName.COMPANY_HOME,
            params: {
                ...to.params,
                businessLine: to.params.businessLine || BusinessLineName.LECLUB.toLowerCase()
            }
        })
    },
    {
        path: '/company/search',
        redirect: (to) => ({
            ...to,
            name: CompaniesRouteName.COMPANY_SEARCH,
            params: {
                ...to.params,
                businessLine: to.params.businessLine || BusinessLineName.LECLUB.toLowerCase()
            }
        })
    },
    {
        path: '/company/details/:slug',
        redirect: (to) => ({
            ...to,
            name: CompaniesRouteName.COMPANY_DETAILS,
            params: {
                ...to.params,
                businessLine: to.params.businessLine || BusinessLineName.LECLUB.toLowerCase()
            }
        })
    },
    {
        path: '/account',
        meta: {
            layout: AccountLayout,
            middleware: [requiredAuthentification],
            hideWhatsNew: true
        },
        component: () => import('@/views/account/AccountHome.vue'),
        redirect: {
            name: RouteName.ACCOUNT_MEMBERSHIP
        },
        children: [
            {
                path: '',
                name: RouteName.ACCOUNT_HOME,
                redirect: { name: RouteName.ACCOUNT_MEMBERSHIP }
            },
            {
                path: 'membership',
                name: RouteName.ACCOUNT_MEMBERSHIP,
                component: () => import('@/views/account/AccountMembership.vue')
            },
            {
                path: 'info',
                name: RouteName.ACCOUNT_INFO,
                component: () => import('@/views/account/AccountInfo.vue')
            },
            {
                path: 'notification',
                name: RouteName.NOTIFICATION_PREFERENCES,
                component: () => import('@/views/account/NotificationPreferences.vue')
            }
        ]
    },
    {
        path: '/club/offboarding',
        name: RouteName.OFFBOARDING_CLUB,
        component: () => import('@/views/offboardings/club/OffboardingClub.vue'),
        meta: {
            layout: EmptyLayout,
            hasGridBackground: true,
            middleware: [requiredAuthentification, requiredRoleLeClub],
            hideWhatsNew: true,
            forceLang: Lang.FR
        }
    },
    {
        path: '/switch-to',
        name: RouteName.SWITCH_TO,
        component: () => import('@/views/auth/switch-to/SwitchTo.vue'),
        meta: {
            layout: SimplisticLayout,
            middleware: [requiredAuthentification]
        }
    },
    {
        path: '/exit-switch-to',
        name: RouteName.EXIT_SWITCH_TO,
        component: () => import('@/views/auth/exit-switch-to/ExitSwitchTo.vue'),
        meta: {
            layout: SimplisticLayout,
            middleware: [requiredAuthentification]
        }
    },
    {
        path: '/profile',
        name: RouteName.MEMBER_ME,
        component: () => import('@/views/members/MemberSingle.vue'),
        meta: {
            middleware: [requiredAuthentification],
            context: SpaName.LENETWORK
        }
    },
    {
        path: '/member/:memberId',
        name: RouteName.MEMBERS_PROFIL,
        component: () => import('@/views/members/MemberSingle.vue'),
        meta: {
            middleware: [requiredAuthentification],
            context: SpaName.LENETWORK
        }
    },
    {
        path: '/events',
        component: () => import('@/views/events/EventListPage.vue'),
        meta: {
            context: SpaName.LENETWORK
        },
        children: [
            {
                path: '',
                name: RouteName.EVENT_LIST,
                redirect: () => {
                    const authStore = useAuthStore()
                    if (authStore.isLoggedIn && authStore.hasBusinessLine(BusinessLineName.LECLUB)) {
                        return { name: RouteName.EVENT_LIST_CLUB }
                    }
                    return { name: RouteName.EVENT_LIST_PUBLIC }
                }
            },
            {
                path: 'public',
                name: RouteName.EVENT_LIST_PUBLIC,
                component: () => import('@/views/events/tabs/EventListTab.vue')
            },
            {
                path: 'club',
                name: RouteName.EVENT_LIST_CLUB,
                component: () => import('@/views/events/tabs/EventListTab.vue')
            },
            {
                path: 'user',
                name: RouteName.EVENT_LIST_USER,
                meta: {
                    middleware: [requiredAuthentification]
                },
                component: () => import('@/views/events/tabs/EventMeListTab.vue')
            },
            {
                path: 'external-events',
                name: RouteName.EVENT_LIST_EXTERNAL,
                meta: {
                    middleware: [requiredAuthentification]
                },
                component: () => import('@/views/events/tabs/EventExternalListTab.vue')
            },
            {
                path: 'physical',
                name: RouteName.EVENT_LIST_PHYSICAL,
                redirect: (to) => ({
                    ...to,
                    name: RouteName.EVENT_LIST
                })
            },
            {
                path: 'live',
                name: RouteName.EVENT_LIST_LIVE,
                redirect: (to) => ({
                    ...to,
                    name: RouteName.EVENT_LIST
                })
            },
            {
                path: 'networking',
                name: RouteName.EVENT_LIST_NETWORKING,
                redirect: (to) => ({
                    ...to,
                    name: RouteName.EVENT_LIST
                })
            }
        ]
    },
    {
        path: '/onboarding',
        component: () => import('@/views/onboardings/OnboardingPage.vue'),
        name: RouteName.ONBOARDING_BASE,
        meta: {
            layout: SimplisticLayout,
            middleware: [requiredAuthentification],
            context: SpaName.LENETWORK
        },
        children: [
            {
                path: '',
                name: RouteName.ONBOARDING,
                component: () => import('@/views/onboardings/steps/OnboardingStepProfile.vue')
            },
            {
                path: 'goal',
                name: RouteName.ONBOARDING_GOAL,
                component: () => import('@/views/onboardings/steps/OnboardingStepGoal.vue')
            },
            {
                path: 'location',
                name: RouteName.ONBOARDING_LOCATION,
                component: () => import('@/views/onboardings/steps/OnboardingStepLocation.vue')
            },
            {
                path: 'hub',
                name: RouteName.ONBOARDING_LOCATION_HUB,
                component: () => import('@/views/onboardings/steps/OnboardingStepHub.vue')
            },
            {
                path: 'talent',
                name: RouteName.ONBOARDING_JOB,
                component: () => import('@/views/onboardings/steps/OnboardingStepJob.vue')
            },
            {
                path: 'job',
                name: RouteName.ONBOARDING_JOB_YES,
                component: () => import('@/views/onboardings/steps/OnboardingStepJobYes.vue')
            },
            {
                path: 'avatar',
                name: RouteName.ONBOARDING_AVATAR,
                component: () => import('@/views/onboardings/steps/OnboardingStepAvatar.vue')
            },
            {
                path: 'complete',
                name: RouteName.ONBOARDING_COMPLETE,
                component: () => import('@/views/onboardings/steps/OnboardingStepComplete.vue')
            }
        ]
    },
    {
        path: '/unsubscribe',
        name: RouteName.NEWSLETTER_UNSUBSCRIBE,
        component: () => import('@/views/common/newsletter/unsubscribe/UnsubscribePage.vue'),
        meta: {
            layout: EmptyLayout,
            context: SpaName.LENETWORK,
            hideWhatsNew: true
        }
    },
    {
        path: '/groups',
        alias: '/circles',
        name: RouteName.GROUP_LIST,
        component: () => import('@/views/circles/home/CircleHome.vue'),
        meta: {
            middleware: [requiredAuthentification],
            layoutProps: {
                extended: 'sm'
            }
        }
    },
    {
        name: RouteName.CIRCLE,
        path: '/groups/:slug',
        alias: '/circles/:slug',
        component: () => import('@/views/circles/single/CircleSingle.vue'),
        meta: {
            middleware: [requiredAuthentification],
            layoutProps: {
                extended: 'sm'
            }
        },
        children: [
            {
                path: '',
                name: RouteName.CIRCLE_SINGLE,
                component: () => import('@/views/circles/single/tabs/circle-home/CircleHome.vue')
            },
            {
                path: 'discussion',
                name: RouteName.CIRCLE_SINGLE_CHAT,
                component: () => import('@/views/circles/single/tabs/circle-chat-room/CircleChatRoom.vue')
            },
            {
                path: 'events',
                name: RouteName.CIRCLE_SINGLE_EVENTS,
                component: () => import('@/views/circles/single/tabs/circle-events/CircleEvents.vue')
            },
            {
                path: 'networking-sessions',
                name: RouteName.CIRCLE_SINGLE_NETWORKING_SESSIONS,
                component: () => import('@/views/circles/single/tabs/CircleNetworkings.vue')
            },
            {
                path: 'members',
                name: RouteName.CIRCLE_SINGLE_MEMBERS,
                component: () => import('@/views/circles/single/tabs/circle-members/CircleMembers.vue')
            },
            {
                path: 'whatsapp',
                name: RouteName.CIRCLE_SINGLE_WHATSAPP,
                component: () => import('@/views/circles/single/tabs/CircleWhatsapp.vue')
            },
            {
                path: 'rules',
                name: RouteName.CIRCLE_SINGLE_RULES,
                component: () => import('@/views/circles/single/tabs/CircleRules.vue')
            },
            {
                path: 'articles',
                name: RouteName.CIRCLE_SINGLE_ARTICLES,
                component: () => import('@/views/circles/single/tabs/circle-articles/CircleArticles.vue')
            }
        ]
    },
    {
        name: RouteName.CIRCLE_JOIN_REGISTER_BPI,
        path: '/circles/cfo-bpi/join',
        component: () => import('@/views/circles/circle-join-bpi/CircleJoinBpi.vue'),
        meta: {
            middleware: [requiredAuthentification],
            layoutProps: {
                extended: 'sm'
            },
            layout: EmptyLayout,
            context: SpaName.LENETWORK
        }
    },
    {
        name: RouteName.CIRCLE_JOIN_REGISTER,
        path: '/circles/:slug/join',
        component: () => import('@/views/circles/CircleJoin.vue'),
        meta: {
            middleware: [requiredAuthentification],
            layoutProps: {
                extended: 'sm'
            },
            context: SpaName.LENETWORK
        }
    },
    {
        name: RouteName.CIRCLE_JOIN_FROM_INVITE_LINK,
        path: '/circles/:slug/invite/:token',
        component: () => import('@/views/circles/CircleJoinFromInviteLink.vue'),
        meta: {
            layoutProps: {
                extended: 'sm'
            },
            context: SpaName.LENETWORK
        }
    },
    {
        path: '/404',
        name: RouteName.NOT_FOUND,
        component: () => import('@/views/NotFoundPage.vue'),
        meta: {
            layout: EmptyLayout,
            context: SpaName.LENETWORK,
            hideWhatsNew: true
        }
    },
    {
        path: '/joinretail',
        name: RouteName.QRCODE_JOIN_RETAIL,
        component: () => import('@/components/layouts/EmptyLayout.vue'),
        beforeEnter: () => {
            window.location.href = 'https://about.frenchfounders.com/joinretail'
        }
    },
    {
        path: '/resetting/request',
        name: RouteName.PASSWORD_FORGOT,
        component: () => import('@/views/auth/reset-password/PasswordForgot.vue'),
        meta: {
            layout: AccountLayout,
            layoutProps: { backLink: RouteName.LOGIN },
            hideWhatsNew: true
        }
    },
    {
        path: '/resetting/reset/:token',
        name: RouteName.RESET_PASSWORD,
        component: () => import('@/views/auth/reset-password/ResetPassword.vue'),
        meta: {
            layout: AccountLayout,
            layoutProps: { backLink: RouteName.LOGIN },
            hideWhatsNew: true
        }
    },
    {
        path: '/login',
        alias: '/register',
        name: RouteName.LOGIN,
        component: () => import('@/views/auth/login/LoginPage.vue'),
        beforeEnter: [redirectToHomeIfAlreadyConnected],
        meta: {
            layout: EmptyLayout
        }
    },
    {
        path: '/logout',
        name: RouteName.LOGOUT,
        component: () => import('@/views/auth/logout/LogoutPage.vue'),
        meta: {
            layout: EmptyLayout,
            hideWhatsNew: true
        }
    },
    {
        path: '/discussion/:discussionSlug',
        name: RouteName.DISCUSSION_SINGLE,
        component: () => import('@/views/network/discussions/discussion-single/DiscussionSingle.vue')
    },
    // A placer en dernier 👇
    {
        path: '/:pathMatch(.*)*',
        component: () => import('@/views/NotFoundPage.vue'),
        meta: {
            layout: EmptyLayout
        }
    }
]

export default routes
