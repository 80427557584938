export enum StorageKey {
    LOCALE = 'locale',
    PUBLIC_PARTICIPANT = 'publicParticipant',
    PUBLIC_PARTICIPANT_ID = 'publicParticipantId',
    SHOW_MODAL_RM_MEET = 'showModalAskMeetingRm',
    APPLY_VIP_ID = 'applyVipId',
    DEALFLOW = 'hideDealflowExplications',
    PERSONA_SELECTED = 'persona_selected',
    COOKIES = 'cookieConsent',
    LOST_REMINDER = 'lost_reminder',
    SEARCH_HISTORY = 'search_history',
    SCAN_EVENT_EMAIL = 'scan_event_email',
    PRODUCT_UPDATE_LAST_DATE = 'product_update_last_date',
    PARTICIPATE_BETA_TEST_MODAL = 'PARTICIPATE_BETA_TEST_MODAL',
    CLUB_HOMEPAGE_POLL_FINISH = 'CLUB_HOMEPAGE_POLL_FINISH',
    CLUB_HOMEPAGE_SUGGESTION_HIDE = 'CLUB_HOMEPAGE_SUGGESTION_HIDE'
}
