<script setup lang="ts">
    import HeaderNavigationTitle from './HeaderNavigationTitle.vue'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import { HeaderMenuItem } from '@/types/Header.type'
    import { ref } from 'vue'
    import { CompaniesRouteName, ClubRouteName } from '@/vars/RouteName'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'

    const groupLocationShow = ref('')

    defineProps<{
        menu: HeaderMenuItem[]
    }>()

    defineEmits<{
        close: []
    }>()

    function openFolderLocation(name: string) {
        if (groupLocationShow.value === name) {
            groupLocationShow.value = ''
        } else {
            groupLocationShow.value = name
        }
    }
</script>

<template>
    <div class="menu" :class="`menu--col-${menu.length}`">
        <div
            v-for="cell in menu"
            :key="cell.key"
            class="menu__cell"
            :class="{
                'menu__cell--even': cell.even
            }"
        >
            <HeaderNavigationTitle v-if="cell.title" :title="cell.title" :subtitle="cell.subtitle" :icon="cell.icon" />
            <div v-if="cell.description" class="menu__body menu__body--description">
                <p class="menu__description">{{ cell.description }}</p>
            </div>
            <div v-if="cell.links" class="menu__body">
                <template v-for="(link, index) in cell.links" :key="index">
                    <Component
                        :is="link.to ? 'RouterLink' : 'a'"
                        :to="link.to"
                        :href="link.href"
                        :data-important="link.label"
                        class="menu__link"
                        @click="$emit('close')"
                    >
                        <span class="menu__link-inner">
                            <BaseIcon v-if="link.icon" legacy :name="link.icon" class="menu__link-icon" />
                            {{ link.label }}
                        </span>
                    </Component>
                </template>
            </div>
            <!-- HUBS -->
            <div v-else-if="cell.categories" class="menu__body menu__body--categories menu__column">
                <template v-if="cell.myHubValue">
                    <SpaRouterLink
                        class="menu__link"
                        :to="{ name: ClubRouteName.HUB_SINGLE, params: { hub: cell.myHubValue.slug } }"
                        @click="$emit('close')"
                    >
                        {{ cell.myHub }} :
                        {{ cell.myHubValue.name }}
                    </SpaRouterLink>
                </template>
                <template
                    v-if="cell.secondariesHubs && cell.secondariesHubsValues && cell.secondariesHubsValues?.length > 0"
                >
                    {{ cell.secondariesHubs }} :
                    <SpaRouterLink
                        v-for="hub in cell.secondariesHubsValues"
                        :key="hub.id"
                        class="menu__link"
                        :to="{ name: ClubRouteName.HUB_SINGLE, params: { hub: hub.slug } }"
                        @click="$emit('close')"
                    >
                        {{ hub.name }}
                    </SpaRouterLink>
                </template>
                <span v-if="cell.allHubs" class="menu__all-hubs">{{ cell.allHubs }}</span>
                <div v-for="category in cell?.categories" :key="category.name">
                    <div class="menu__category-name" @click="openFolderLocation(category.name)">
                        {{ category.name }}
                    </div>
                    <div v-show="groupLocationShow === category.name" class="menu__group-location">
                        <a
                            v-for="hub in category.children"
                            :key="hub.label"
                            :href="hub.href"
                            :data-important="hub.label"
                            class="menu__link menu__pos-relative menu__category-elem"
                            @click="$emit('close')"
                        >
                            {{ hub.label }}
                        </a>
                    </div>
                </div>
            </div>
            <!-- Company -->
            <div v-if="cell.seeCompanies && cell.newsCompanies" class="menu__body">
                <span class="menu__news-companies">
                    {{ cell.newsCompanies }}
                </span>
                <div class="menu__company-container">
                    <RouterLink
                        v-for="company in cell?.companies"
                        :key="company.id"
                        class="menu__link"
                        :to="{ name: CompaniesRouteName.COMPANY_DETAILS, params: { slug: company.slug } }"
                        @click="$emit('close')"
                    >
                        {{ company.name }}
                    </RouterLink>
                </div>
            </div>
            <div v-if="cell.seeCompanies" class="menu__footer">
                <RouterLink
                    class="menu__footer-link"
                    :to="{ name: CompaniesRouteName.COMPANY_HOME }"
                    @click="$emit('close')"
                >
                    <BaseIcon legacy name="arrow-right" class="menu__footer-icon" />
                    {{ cell.seeCompanies }}
                </RouterLink>
            </div>
            <!-- Member -->
            <div v-if="cell.footerLinks && cell.footerLinks.length > 0" class="menu__footer">
                <template v-for="link in cell.footerLinks" :key="link.label">
                    <Component
                        :is="link.to ? 'RouterLink' : 'a'"
                        :to="link.to"
                        :href="link.href"
                        :data-important="link.label"
                        class="menu__footer-link"
                        @click="$emit('close')"
                    >
                        <BaseIcon legacy name="arrow-right" class="menu__footer-icon" />
                        {{ link.label }}
                    </Component>
                </template>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .menu {
        $block-selector: &;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        padding: 1.25em 0;

        &--col-2,
        &--col-3 {
            grid-template-columns: repeat(3, 1fr);
        }

        &__cell {
            padding: 0 1em;
            border-left: 1px solid var(--theme-border-color);

            &:first-child {
                padding-left: 0;
                border: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }

        &__body {
            padding-left: 2.1875em;
            margin-bottom: 1.875em;

            &--categories {
                display: flex;
            }

            &--description {
                max-width: 18.75em;
            }
        }

        &__description {
            font-size: 0.875em;
        }

        &__category {
            padding: 0 1em;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
        &__category-name {
            cursor: pointer;
        }
        &__pos-relative {
            position: relative;
        }
        &__category-hub {
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
        }

        &__category-title {
            margin-top: 14px;
            font-size: 14px;
            font-weight: bold;
            padding-bottom: 7px;
        }

        &__category-elem {
            margin: 0 !important;
            border: 0 !important;
            padding: 0 !important;
        }
        &__group-location {
            padding-bottom: 15px;
            margin-left: 10px;
        }

        &__placeholder {
            display: flex;
            flex-direction: column;
        }
        &__column {
            display: flex;
            flex-direction: column;
        }

        &__category-name {
            font-size: 0.875em;
            font-weight: bold;
            text-transform: uppercase;
            padding-bottom: 0.5em;
        }

        &__link {
            display: block;
            width: 100%;
            font-size: 0.875em;
            padding: 0.5em 0;
            transition: 350ms;
            cursor: pointer;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }

            &::before {
                content: attr(data-important);
                height: 0;
                overflow: hidden;
                visibility: hidden;
                pointer-events: none;
                display: block;
            }

            &::before,
            &:hover {
                font-weight: bold;
            }
        }

        &__link-inner {
            display: flex;
        }

        &__link-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.25em;
            margin-right: 0.5em;
            width: 1em;
            height: 1em;
            cursor: pointer;
        }

        &__link-description {
            display: block;
            font-size: 0.75em;
            line-height: 1.2;
            color: var(--theme-text-color--secondary);
        }

        &__footer {
            padding-left: 2.1875em;
        }

        &__footer-link {
            display: flex;
            align-items: center;
            transition: 350ms;
            margin-bottom: 0.625em;
            font-weight: bold;
            cursor: pointer;

            &:hover {
                color: var(--theme-primary-color);
            }
        }

        &__footer-icon {
            transition: 350ms;
            color: var(--theme-primary-color);
            width: 0;
            overflow: hidden;

            #{$block-selector}__footer-link:hover & {
                width: 1.25em;
            }
        }

        &__all-hubs {
            padding-bottom: 0.5em;
            padding-top: 1em;
            font-weight: bold;
        }

        &__company-container {
            margin-left: 1em;
            padding-top: 0.6em;
        }
    }
</style>
